import React from 'react';
import { Image, Container } from 'react-bootstrap';

import orgIcon from '../../assets/images/icons/orgIcon.svg';
import orgLogo from '../../assets/images/LDA_Logo_Color.png';
import orgImage from '../../assets/images/DR-BG-2.jpg';

/*
    Header box container on user page. 

    Contains user banner and icon

    DOES NOT CONTAIN SITE SWITCHER DROPDOWN
*/

export default function HeaderBox(props) {

    if (props.hasImage) {
        return (
            <Container className="container-hBox">
                <div className="row-hBox">
                    <div className="grid-item grid-item-1">
                        <div className="hBox-info">
                            <div className="hBox-logo">
                                <div className="d1">
                                    <figure className="user-org-logo">
                                        <Image className="org-logo" src={props.orgLogoLink} />
                                    </figure>
                                </div>
                            </div>
                            <div className="hBox-text">
                                <div className="d2">
                                    <pre><h1>Welcome Back, </h1><h1 className="h1-b">{props.name}</h1></pre>
                                </div>
                                <div className="d3">
                                    <Image src={orgIcon} /><h6>{props.orgName}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-item grid-item-2">
                        <Image className="org-Image" src={props.orgImageLink}></Image>
                    </div>
                </div>
            </Container>

        );
    } else {
        return (
            <Container className="container-hBox">
                <div className="row-hBox">
                    <div className="grid-item grid-item-1">
                        <div className="hBox-info">
                            <div className="hBox-logo">
                                <div className="d1">
                                    <figure className="user-org-logo">
                                        <Image className="org-logo" src={orgLogo} />
                                    </figure>
                                </div>
                            </div>
                            <div className="hBox-text">
                                <div className="d2">
                                    <pre><h1>Welcome Back, </h1><h1 className="h1-b">{props.name}</h1></pre>
                                </div>
                                <div className="d3">
                                    <Image src={orgIcon} /><h6>{props.orgName}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-item grid-item-2">
                        <Image className="org-Image" src={orgImage}></Image>
                    </div>
                </div>
            </Container>
        );
    }
}