/*
    State information for creating an org

    Contains all information that is included in create org form

*/

const createOrgReducer = (
  state = {
    org: {
      formEmail: "",
      formPassword: "",
      companyName: "",
      hasImage: false,
      clubName: "",
      firebaseDomain: "",
      dbURL: "",
      projID: "",
      groups: {},
      bucket: "",
      senderID: "",
      apiKey: "",
      memberCategories: [],
    },
    orgFlags: {
      /*1: false, // isCBIGG
      2: false, // isPGA
      3: false, // isPrivate
      4: false, // isPublic
      5: false, // isGolf
      6: false, // isSmallBusiness,*/
      groups: {},
      hasForms: false,
      hasDatabase: false,
    },
  },
  action
) => {
  switch (action.type) {
    case "ORG_CREATION":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default createOrgReducer;
