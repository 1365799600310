/*
    State information for UUID

    Stores the UUID of a user

*/

const uuidReducer = (state = { uuid: "" }, action) => {
    switch (action.type) {
        case "IS_UUID":
            return { ...state, uuid: action.payload };
        default:
            return state;
    }
}

export default uuidReducer;