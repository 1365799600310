/*
    Services utilized by an admin user

    Includes:

        createUser  - creates a user in database
        getUsers    - returns a list of users for admin dashboard
        updateUser  - modifies user information
        deleteUser  - deletes a user from database
        getOrg      - returns information from a specific org
        updateOrg   - modifies org information
        getOrgs     - returns list of orgs for admin dashboard
        getOrgUsers - returns a list of users from a specific org
        createOrg   - creates an organization
        deleteOrg   - deletes an organization
*/

import http from "../http-common";

const header = {
  headers: {
    'apiKey': process.env.REACT_APP_API_KEY
  }
}

class AdminService {
  createUser(data) {
    return http.post("/user", data, header);
  } 

  getUsers() {
    return http.get("/users", header);
  } 

  updateUser(data) {
    return http.post("/updateUser", data, header);
  }  

  deleteUser(data) {
    return http.post("/deleteUser", data, header);
  } 

  permanentDeleteUser(data) {
    return http.post("/permanentDeleteUser", data, header);
  } 

  unDeleteUser(data) {
    return http.post("/recoverUser", data, header);
  } 

  resendPass(data){
    return http.post("/resendPass", data, header);
  } // TODO

  getOrg(data) {
    return http.get("/org", {
      headers:{
        'apiKey': process.env.REACT_APP_API_KEY 
      },
      params: {
        guid: data,
      },
    });
  }

  updateOrg(data) {
    return http.post("/updateOrg", data, header);
  } 

  getOrgs() {
    return http.get("/orgs", header);
  } 

  getOrgUsers(data) {
    return http.get("/orgUsers", {
      headers:{
        'apiKey': process.env.REACT_APP_API_KEY 
      },
      params: {
        guid: data,
      },
    });
  } 

  createOrg(data) {
    return http.post("/org", data, header);
  } 

  deleteOrg(data) {
    return http.post("/deleteOrg", data, header);
  } 

  permanentDeleteOrg(data) {
    return http.post("/permanentDeleteOrg", data, header);
  } 

  unDeleteOrg(data) {
    return http.post("/recoverOrg", data, header);
  } 

  createGroup(data){
    return http.post("/group", data, header);
  } 

  getGroups(){
    return http.get("/groups", header);
  } 

  deleteGroup(data){
    return http.post("/deleteGroup", data, header);
  } 

  updateGroup(data){
    return http.post("/updateGroup", data, header);
  } 

  addGroupsToOrg(data){
    return http.post("/orgGroupsAdd", data, header);
  } 

  removeGroupFromOrg(data){
    return http.post("/orgGroupsRemove", data, header);
  }
}

export default new AdminService();
