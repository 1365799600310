const boxReducer = (state = { accessToken: "" }, action) => {
  switch (action.type) {
    case "GET_TOKEN":
      return {
        ...state,
        accessToken: action.payload,
      };
    default:
      return state;
  }
};

export default boxReducer;
