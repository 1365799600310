import React, { Component } from "react";
import ContentPreview from "box-ui-elements/es/elements/content-preview";
import { IntlProvider } from "react-intl";
//import getboxToken from "../../services/boxService";
import BoxService from "../../services/boxService";
import Logo from '../../assets/images/New-DR-Waving-Flag.gif';

class FilePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      fileId: "",
    };
  }

  componentDidMount() {
    this.setState({ fileId: window.location.search.replace("?id=", "") });

    //getboxToken().then((res) => {
    BoxService.getBoxToken().then((res) => {
      this.setState({ token: res.data.access_token });
    });
  }

  render() {

    return (
      <div style={{ height: "100vh" }}>
        {this.state.token !== "" && (
          <IntlProvider locale="en">
            <ContentPreview
              logoUrl={Logo}
              hasHeader={true}
              fileId={this.state.fileId}
              token={this.state.token}
            />
          </IntlProvider>
        )}
      </div>
    );
  }
}

export default FilePreview;
