import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/Circle-load.json';

class CircleLottie extends Component {
    
    render() {
        const spacingStyle = {
            margin: '20px',
            cursor: 'default',
        }

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            className: "loginLottie",
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div>
                <Lottie 
                    style={spacingStyle} 
                    className="Lottie" 
                    options={defaultOptions}
                    height={70}
                    width={70}
                />
            </div>
        )
    }
}

export default CircleLottie;