import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
} from "react-bootstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { AdminService } from "../../services";
import { setCreateUser } from "../../actions";

/*
    Create user page

    blank form for new user submission
*/

export default function CreateUser(props) {
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    org: "",
    type: "",
    new: true,
    guid: "",
    createdAt: "",
    clientID: "",
    subLink: "",
    folder: "",
    eventLink: "",
    firstName: "",
    lastName: "",
  });

  const [userFlags, setUserFlags] = useState({
    hasWebsite: false,
    hasEmail: false,
    hasForms: false,
    hasDesign: false,
    hasFiles: false,
    hasClubchat: false,
    hasEvents: false,
    hasDatabase: false,
  });

  useEffect(() => {
    handleUpdate();
    // console.log(userInfo, userFlags, "-----------------create User");
    // PUT FIELD CLEARING HERE
  }, [userInfo, userFlags]);
  useEffect(() => {
    getOrg();
  }, []);

  const dispatch = useDispatch();

  const [orgs, setOrgs] = useState([]);
  const roles = [
    { name: 'Users', label: 'User' },
    { name: 'SubAdmins', label: 'Sub Admin' },
    { name: "Admins", label: 'Admin' }
  ];

  // Creatable
  const [org, setOrg] = useState({ name: "", label: "" });
  const [role, setRole] = useState({ name: "", label: "" });

  async function getOrg() {
    await AdminService.getOrgs().then((res) => {
      // console.log(res.data);
      for (let x of res.data) {
        setOrgs((prevOrgs) =>
          prevOrgs.concat({
            name: x.value,
            label: x.name.replaceAll("_", " "),
          })
        );
      }
    });
  }
  // console.log(orgs);

  function handleButtonClick(e, x) {
    e.preventDefault();

    setUserFlags((prevState) => ({
      ...prevState,
      [x]: !userFlags[x],
    }));

    dispatch(
      setCreateUser({
        user: userInfo,
        userFlags: userFlags,
      })
    );
  }

  function handleUpdate() {
    //console.log(x + ": " + e.target.value);

    dispatch(
      setCreateUser({
        user: userInfo,
        userFlags: userFlags,
      })
    );
  }

  function handleOrgChange(e) {
    setOrg(e);

    // console.log(e);

    if (e !== null) {
      if (e && e.__isNew__) {
        var luuid = uuidv4();

        setUserInfo((prevState) => ({
          ...prevState,
          org: e.label,
          guid: luuid,
        }));
        // Create guid and send to backend
      } else {
        setUserInfo((prevState) => ({
          ...prevState,
          org: e.label,
          guid: e.name,
        }));
        // console.log(e);
      }
    }
  }

  function handleRoleChange(e) {
    setRole(e);
    // console.log(e);

    if (e !== null) {
      setUserInfo((prevState) => ({
        ...prevState,
        type: e.name,
      }));
        // Create guid and send to backend 
    } else {
      setUserInfo((prevState) => ({
        ...prevState,
        type: "",
      }));
    }
    //console.log("User type: ", e.name);
  }

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      display: "inline-block",
    }),
    dropdownIndicator: () => ({
      display: "none",
      visibility: "hidden,",
    }),
    indicatorSeparator: () => ({
      display: "none",
      visibility: "hidden,",
    }),
    clearIndicator: () => ({
      padding: "0 4px",
    }),

    option: () => ({
      borderBottom: "1px solid #d3dae1",
      padding: "5px 12px",
      fontSize: "1.3rem",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? "none" : "none",
      border: state.isFocused ? "none" : "none",
      minHeight: "20px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 0 0 0",
      backgroundColor: "#f4f5f9",
      borderRadius: "4px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "1.5rem",
      fontWeight: "500",
      paddingLeft: "8px",
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "1.5rem",
      fontWeight: "500",
      margin: "0",
      paddingTop: "0",
      paddingBottom: "0",
      paddingLeft:"8",
      width: "100%",
      backgroundColor: state.isDisabled ? "white" : "#f4f5f9",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
  };

  return (
    <div className="admin-interior-view">
      <div className="actor-main-info">
        <Row className="admin-row">
          <Col className="admin-col">
            <b className="info-title create-title">First Name: </b>
            <input
              className="h1-a admin-input"
              id="User"
              value={userInfo.firstName}
              autoFocus
              onChange={(e) => {
                e.persist();
                setUserInfo((prevState) => ({
                  ...prevState,
                  firstName: e.target.value,
                }));

                handleUpdate();
              }}
              type="text"
            />
          </Col>
          <Col className="admin-col">
            <b className="info-title create-title">Last Name: </b>
            <input
              className="h1-a admin-input"
              id="User"
              value={userInfo.lastName}
              onChange={(e) => {
                e.persist();
                setUserInfo((prevState) => ({
                  ...prevState,
                  lastName: e.target.value,
                }));

                handleUpdate();
              }}
              type="text"
            />
          </Col>
        </Row>
        <Row className="admin-row">
          <Col xs={5} className="admin-col">
            <b className="info-title">Email: </b>
            <input
              className="admin-input"
              type="text"
              value={userInfo.email}
              onChange={(e) => {
                e.persist();
                setUserInfo((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }));

                handleUpdate();
              }}
            />
          </Col>
          <Col  className="admin-col">
            <b className="info-title">Role: </b>
            <Select
              isClearable
              styles={customStyles}
              classNamePrefix="org-select"
              autoComplete="new-password"
              placeholder=""
              options={roles}
              value={role}
              onChange={(e) => {
                handleRoleChange(e);
                handleUpdate();
              }}
            />
          </Col>
          <Col xs={5} className="admin-col">
            <b className="info-title">Organization: </b>
            <Select
              isClearable
              styles={customStyles}
              classNamePrefix="org-select"
              autoComplete="new-password"
              placeholder=""
              options={orgs}
              value={org}
              onChange={(e) => {
                handleOrgChange(e);
                handleUpdate();
              }}
            />
          </Col>
        </Row>
        <Row className="admin-row">
          <Col className="admin-col">
            <b className="info-title">Password: </b>
            <input
              className="admin-input"
              autoComplete="new-password"
              type="text"
              value={userInfo.password}
              onChange={(e) => {
                e.persist();
                setUserInfo((prevState) => ({
                  ...prevState,
                  password: e.target.value,
                }));

                handleUpdate();
              }}
            />
          </Col>
          <Col className="admin-col">
            <b className="info-title">Confirm Password: </b>
            <input
              className="admin-input"
              autoComplete="new-password"
              type="text"
              value={userInfo.confirmPassword}
              onChange={(e) => {
                e.persist();
                setUserInfo((prevState) => ({
                  ...prevState,
                  confirmPassword: e.target.value,
                }));

                //handleUpdate(e, "password")
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="actor-services">
        <Button
          className={userFlags.hasWebsite ? "btn-bc" : "btn-bd"}
          onClick={(e) => handleButtonClick(e, "hasWebsite")}
        >
          <i className="icon-user icon-w"></i>Website
        </Button>
        <Button
          className={userFlags.hasEmail ? "btn-bc" : "btn-bd"}
          onClick={(e) => handleButtonClick(e, "hasEmail")}
        >
          <i className="icon-user icon-e"></i>Email
        </Button>
        <Button
          className={userFlags.hasForms ? "btn-bc" : "btn-bd"}
          onClick={(e) => handleButtonClick(e, "hasForms")}
        >
          <i className="icon-user icon-f"></i>Forms
        </Button>
        <Button
          className={userFlags.hasDesign ? "btn-bc" : "btn-bd"}
          onClick={(e) => handleButtonClick(e, "hasDesign")}
        >
          <i className="icon-user icon-d"></i>Design
        </Button>
        <Button
          className={userFlags.hasFiles ? "btn-bc" : "btn-bd"}
          onClick={(e) => handleButtonClick(e, "hasFiles")}
        >
          <i className="icon-user icon-fl"></i>Files
        </Button>
        <Button
          className={userFlags.hasClubchat ? "btn-bc" : "btn-bd"}
          onClick={(e) => handleButtonClick(e, "hasClubchat")}
        >
          <i className="icon-user icon-c"></i>ClubChat
        </Button>
        <Button
          className={userFlags.hasEvents ? "btn-bc" : "btn-bd"}
          onClick={(e) => handleButtonClick(e, "hasEvents")}
        >
          <i className="icon-user icon-ev"></i>Events
        </Button>
        <Button
          className={userFlags.hasDatabase ? "btn-bc" : "btn-bd"}
          onClick={(e) => handleButtonClick(e, "hasDatabase")}
        >
          <i className="icon-user icon-db"></i>Database
        </Button>
      </div>
      {/*userFlags.hasEmail && (
        <div className="admin-service">
          <b className="service-title">Email</b>
          <div className="service-info">
            <b className="info-title">Client ID:</b>
            <input
              className="admin-input"
              type="text"
              value={userInfo.clientID}
              onChange={(e) => {
                e.persist();
                setUserInfo((prevState) => ({
                  ...prevState,
                  clientID: e.target.value,
                }));

                handleUpdate();
              }}
            />
          </div>
          <div className="service-info">
            <b className="info-title">Add Subscriber Link:</b>
            <input
              className="admin-input"
              type="text"
              value={userInfo.subLink}
              onChange={(e) => {
                e.persist();
                setUserInfo((prevState) => ({
                  ...prevState,
                  subLink: e.target.value,
                }));

                handleUpdate();
              }}
            />
          </div>
        </div>
      )*/}{" "}
      {/*userFlags.hasFiles && (
        <div className="admin-service">
          <b className="service-title">Files</b>
          <div className="service-info">
            <b className="info-title">Folder ID:</b>
            <input
              className="admin-input"
              type="text"
              value={userInfo.folderID}
              onChange={(e) => {
                e.persist();
                setUserInfo((prevState) => ({
                  ...prevState,
                  folder: e.target.value,
                }));

                handleUpdate();
              }}
            />
          </div>
        </div>
            )*/}{" "}
      {/*userFlags.hasEvents && (
        <div className="admin-service">
          <b className="service-title">Events</b>
          <div className="service-info">
            <b className="info-title">Events Link:</b>
            <input
              className="admin-input"
              type="text"
              value={userInfo.eventLink}
              onChange={(e) => {
                e.persist();
                setUserInfo((prevState) => ({
                  ...prevState,
                  eventLink: e.target.value,
                }));

                handleUpdate();
              }}
            />
          </div>
        </div>
            )*/}
    </div>
  );
}

//<input className="admin-input" type="text" value={userInfo.org !== undefined ? userInfo.org.replaceAll('_', ' ') : 0} />
