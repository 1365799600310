import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/Puff.json';

class LoginLottie extends Component {

  render(){
    let spacingStyle = {}
    if(this.props.styleProp){
      spacingStyle = this.props.styleProp
    }else{
      spacingStyle = {
        margin: '-300px 0 0 0',
        cursor: 'default',
      }
    }

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      className: "loginLottie",
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div>
        <Lottie style={spacingStyle} className="loginLottie" options={defaultOptions}
              height={200}
              width={200}
        />
      </div>
    )
  }
}

export default LoginLottie;