/*
    Services utilized by Zendesk

    Includes: 

    sendZendeskTicket - sends support ticket 
*/

import http from "../http-common";

const header = {
    headers: {
      'apiKey': process.env.REACT_APP_API_KEY
    }
  }
class ZendeskService {
    sendZendeskTicket(data) {
        var body = {
            email: data.email,
            subject: data.ticketSubject,
            comment: data.ticketDetails,
            upload: data.upload,
            links: data.links
        }

        return http.post("/support", body, header);
    }
}

export default new ZendeskService();