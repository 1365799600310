import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import cookie from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { login, setUser, setUUID } from "./actions";

import Login from "./components/Login";
import Home from "./components/Home";
import CanvaLogin from "./components/sso/canvaLogin";
import CanvaAuthorization from "./components/sso/canvaAuthorization";

//import getboxToken from "./services/boxService";
import BoxService from "./services/boxService";
import FilePreview from "./components/userPages/filePreview";
import FolderPreview from "./components/userPages/folderPreview";
import ForgotPassword from "./components/ForgotPassword";

function App() {
  const isLogged = useSelector((state) => state.isLogged); // Checks the state of user authentication
  const dispatch = useDispatch(); // Initialization of redux state modifier

  const cookieToken = cookie.load("accessToken");
  useEffect(() => {
    async function fetchToken() {
      if (!cookieToken) {
        //const res = await getboxToken();
        const res = await BoxService.getBoxToken();
        cookie.save("accessToken", res.data.access_token, {
          path: "/",
          maxAge: res.data.expires_in,
        });
      }
    }
    fetchToken();
  }, [cookieToken, dispatch]);

  useEffect(() => {
    // useEffect() ALWAYS INVOKED UPON EACH RENDER
    var userResult; // Stores user classification

    async function preCheck() {
      // Chain of login events
      try {
        await Auth.currentSession();
        await Auth.currentAuthenticatedUser().then((user) => {
          dispatch(setUUID(user.attributes.sub));

          if (
            user.signInUserSession.accessToken.payload["cognito:groups"][0] ===
            "Users"
          ) {
            userResult = "user";
            window.IntercomLoad();
          }

          if (
            user.signInUserSession.accessToken.payload["cognito:groups"][0] ===
            "Admins"
          ) {
            userResult = "admin";
            window.IntercomHide();
          }
        });

        dispatch(setUser(userResult));
        dispatch(login());
      } catch (e) {
        if (e !== "No Current User") {
          //alert(e);										// need to decide the alert upon this case
        }
      }
    }

    preCheck(); // Call precheck chain
  }, []);

  // Function declarations for Login page and Home page (DOES NOT RENDER IMMEDIATELY, MUST BE CALLED BY RETURN)

  // --------------------------------------------------------------------

  // Condition : Checks login status and calls appropriate function
  // This is where everything is rendered
  // <Switch>
  //   <Route path="/" exact>
  //     {!isLogged ? <Login /> : <Home />}
  //   </Route>
  // </Switch>;
  if (!isLogged) {
    if (window.location.pathname.includes("file-preview")) {
      return <FilePreview />;
    } else if (window.location.pathname.includes("folder-preview")) {
      return <FolderPreview />;
    } else if(window.location.pathname.includes("sso/canva")) {
      return <CanvaLogin />
    }
    else if(window.location.pathname.includes("forgot-password")){
      return <ForgotPassword />
    }
    else {
      return <Login/>;
    }
  } else {
    if (window.location.pathname.includes("file-preview")) {
      return <FilePreview />;
    } else if (window.location.pathname.includes("folder-preview")) {
      return <FolderPreview />;
    } else if(window.location.pathname.includes("sso/canva")) {
      return <CanvaAuthorization />
    } 
    else if(window.location.pathname.includes("forgot-password")){
      return <ForgotPassword />
    }else {
      return <Home />;
    }
  }
}

export default App;
