import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import Logo from '../../assets/images/New-DR-Waving-Flag.gif';
import dropDown from '../../assets/images/icons/up-arrow.svg';
import { UserService, WebsiteService, FormService } from '../../services';
import DesignModal from '../DesignModal';
/*
  Header block for admin page

  contains links to all other webapps, is the bar at the top of page
*/

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));


const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toUpperCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);

class AdminHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasForms: false,
      hasFiles: false,
      hasEmail: false,
      hasWebsite: false,
      hasDesign: false,
      hasClubchat: false,
      hasDatabase: false,
      hasEvents: false,
      uuid: props.uuid,
      guid: props.guid,
      websiteLink: "",
      formsLink: "",
      webGeneric: "",
      email: props.email,
      designDropdown: false,
      modal: false,
    }
    this.openCanva = this.openCanva.bind(this);
    this.showDesignDropdown = this.showDesignDropdown.bind(this);
    this.hideDesignDropdown = this.hideDesignDropdown.bind(this);
  }

  openCanva = () => {
    //window.open('https://www.canva.com/', '_blank', 'nopener,noreferrer');
    const newWindow = window.open('https://www.canva.com/', '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null // open a tab instead of a new window
  }
  showDesignDropdown = () => {
    this.setState({ designDropdown: true });
  }
  hideDesignDropdown = () => {
    this.setState({ designDropdown: false });
  }
  showModal = (e) => {
    e.stopPropagation();
    this.setState({ modal: true });
  }
  hideModal = () => {
    this.setState({ modal: false });
  }
  async componentDidMount() {

    //const uuid = useSelector(state => state.isUUID);

    ////////////////////  USR POPULATON ////////////////////

    await UserService.getUserMeta(this.state.uuid).then((res) => {
      //console.log(res);
      /*
      this.setState({
        hasForms: res.data.hasForms,
        hasFiles: res.data.hasFiles,
        hasEmail: res.data.hasEmail,
        hasWebsite: res.data.hasWebsite,
        hasDesign: res.data.hasDesign
      });
      */

      if (res.data.hasForms === 1)
        this.setState({ hasForms: true });

      if (res.data.hasFiles === 1)
        this.setState({ hasFiles: true });

      if (res.data.hasEmail === 1)
        this.setState({ hasEmail: true });

      if (res.data.hasWebsite === 1)
        this.setState({ hasWebsite: true });

      if (res.data.hasDesign === 1)
        this.setState({ hasDesign: true });

      if (res.data.hasClubchat === 1)
        this.setState({ hasClubChat: true });

      if (res.data.hasEvents === 1)
        this.setState({ hasEvents: true });

      if (res.data.hasDatabase === 1)
        this.setState({ hasDatabase: true });

    })

    ////////////////////  API POPULATON ////////////////////



    await FormService.getFormsLink(this.state.guid).then(res => {
      this.setState({ formsLink: res.data });
    })

    //Design Link // do this last

    //Box Link

  };

  async getWeb() {
    await WebsiteService.getWebsite().then(res => {
      this.setState({ webGeneric: res.data.key });
    });

    var args = {
      generic: this.state.webGeneric,
      email: this.state.email
    };

    await WebsiteService.getWebsiteToken(args).then(res => {
      //console.log(res.data);
      window.open(`https://website.digitalrange.com/home?dm_sso=${res.data}`, '_blank')
      // this.setState({ websiteLink: "https://website.digitalrange.com/home?dm_sso=" + res.data });
      //console.log(token);
    });
  }

  render() {
    const { modal } = this.state;
    return (
      <div>
        <header className="header">

          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Logo" href="*/" />
            </Link>
          </div>

          <nav className="header-nav">
            <ul className="adminMenu">
              <li>
                <Link to="/">HOME</Link>
              </li>
              {this.state.hasWebsite &&
                <li>
                  <a onClick={() => this.getWeb()}>WEBSITE</a>
                </li>
              } {this.state.hasEmail &&
                <li>
                  <Link to="/email" >EMAIL</Link>
                </li>
              } {this.state.hasForms &&
                <li>
                  <a href={this.state.formsLink} target="_blank" rel="noopener noreferrer">FORMS</a>
                </li>
              } {this.state.hasDesign &&
                <li>
                  <NavDropdown
                    className="navDD"
                    title="DESIGN"
                    onClick={ this.openCanva }
                    onMouseEnter={ this.showDesignDropdown }
                    onMouseLeave={ this.hideDesignDropdown }
                    show={this.state.designDropdown}
                  >
                    <NavDropdown.Item eventKey="design" className="navDD-item" onClick={this.showModal}>MY DESIGNS</NavDropdown.Item>
                  </NavDropdown>
                </li>
              } {this.state.hasFiles &&
                <li>
                  <Link to="/files">FILES</Link>
                </li>
              } {this.state.hasClubchat &&
                <li>
                  <a href="https://my.clubchat.io" target="_blank" rel="noopener noreferrer">CLUBCHAT</a>
                </li>
              } {this.state.hasEvents &&
                <li>
                  <a href="" target="_blank" rel="noopener noreferrer">EVENTS</a>
                </li>
              } {this.state.hasDatabase &&
                <li>
                  <a href="" target="_blank" rel="noopener noreferrer">DATABASE</a>
                </li>
              }
              <li>
                <Dropdown className="headerDD">
                  <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" bsPrefix='dropdown-toggle'>
                    <div className="dropDown" id={dropDown}>
                      <img
                        id="custom-drop-2"
                        className="dd" src={dropDown} alt="dropdown" />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu} className="menuDD">
                    <Dropdown.Item eventKey="1" href="http://help.digitalrange.com/en/" target="_blank" rel="noopener noreferrer">HELP CENTER</Dropdown.Item>
                    <Dropdown.Divider className="ddDivide" />
                    <Dropdown.Item eventKey="2" href="https://www.digitalrange.com/creative-hub" target="_blank" rel="noopener noreferrer">CREATIVE HUB</Dropdown.Item>
                    <Dropdown.Divider className="ddDivide" />
                    <Dropdown.Item eventKey="3" as={Link} to="/Logout">LOGOUT
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

            </ul>
          </nav >
        </header >
        <DesignModal showModal={modal} handleClose={this.hideModal} />
      </div>
    );
  }
}

export default AdminHeader;
