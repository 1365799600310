/*
    State information for user creation

    Contains all the information included in user Creation form

*/

const createUserReducer = (
  state = {
    user: {
      email: "",
      org: "",
      guid: "",
      createdAt: "",
      clientID: "",
      subLink: "",
      folderID: "",
      eventLink: "",
      name: "",
    },
    userFlags: {
      hasWebsite: false,
      hasEmail: false,
      hasForms: false,
      hasDesign: false,
      hasFiles: false,
      hasClubchat: false,
      hasEvents: false,
      hasDatabase: false,
    },
  },
  action
) => {
  switch (action.type) {
    case "USER_CREATION":
      return { ...state, ...action.payload };
    default:
      return state;
  }
  
};

export default createUserReducer;
