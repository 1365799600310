import React from "react";
import { toast } from "react-toastify";
import cross from "../../assets/images/delete.png";
import done from "../../assets/images/checkbox.png";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";

export const failedPopup = (message) => {
  return toast(
    <div className="d-flex align-items-center">
      <img src={cross} alt="check" width="30px" />
      &nbsp;&nbsp;&nbsp;
      <p style={{ fontFamily: "Montserrat" }}>{message}</p>
    </div>,
    {
      position: "bottom-right",
      autoClose: 5000,
    }
  );
};

export const successPopup = (message) => {
  return toast(
    <div className="d-flex align-items-center">
      <img src={done} alt="check" width="30px" />
      &nbsp;&nbsp;&nbsp;
      <p style={{ fontFamily: "Montserrat" }}>{message}</p>
    </div>,
    {
      position: "bottom-right",
      autoClose: 5000,
    }
  );
};

export const deletePopup = (message, handleRecover) => {
  return toast(
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <img src={done} alt="check" width="30px" />
        &nbsp;&nbsp;&nbsp;
        <p style={{ fontFamily: "Montserrat" }}>{message}</p>
      </div>
      <Button
        style={{
          backgroundColor: "#ffff",
          color: "black",
          "&:hover": {
            color: "#333",
          },
        }}
        onClick={handleRecover}
      >
        Undo
      </Button>
    </div>,
    {
      position: "bottom-right",
      autoClose: 5000,
    }
  );
};
