import http from "../http-common";

const header = {
  headers: {
    'apiKey': process.env.REACT_APP_API_KEY
  }
}

class BoxService{
  getBoxToken(){
    return http.get("/boxAuth", header);
  }

  getFile(data){
    return http.get("/boxFile", {
      headers:{
        'apiKey': process.env.REACT_APP_API_KEY 
      },
      params: {
        fileID: data.fileID,
        accessToken: data.accessToken
      },
    });
  }

  updateShareLink(data){
    return http.put("/updateShareLink", data, header);
  }
}
  
export default new BoxService();






