/*
    State information for user/org view on admin dash

    Collects all pertinent information to display a user/org

*/

const selectionReducer = (state = { 
    actor: {
        name: "",
        id: "",
        type: ""
    }, 
    user: {
        name: "",
        id: ""
    }, 
    org: {
        name: "",
        id: ""
    }
}, action) => {
    switch (action.type) {
        case "IS_SELECT":
            return { ...state, actor: action.payload.actor, user: action.payload.user, org: action.payload.org };
        default:
            return state;
    }
}

export default selectionReducer;