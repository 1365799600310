/*
    Services utilized by Duda

    Includes: 

    getWebsite          - retrieves duda generic key
    getWebsiteToken     - retrieves duda tokenized link
    getSiteList         - retrieves id numbers of sites available
    convertSiteList     - converts site ID into name
    getWebsiteAnalytics - retrieves analytics from duda
    getWebsiteForms     - gets form submissions per site

*/

import http from "../http-common";

const header = {
    headers: {
      'apiKey': process.env.REACT_APP_API_KEY
    }
  }

class WebsiteService {
    getWebsite(data) {
        return http.get("/Website", header);
    }

    getWebsiteToken(data) {
        return http.get("/WebsiteToken", {
            headers:{
                'apiKey': process.env.REACT_APP_API_KEY 
            },
            params: {
                email: data.email,
                generic: data.generic
            }
        });
    }
    

    getSiteList(data) {
        return http.get("/SiteList", {
            headers:{
                'apiKey': process.env.REACT_APP_API_KEY 
            },
            params: {
                email: data
            }
        });
    }

    convertSiteList(data) {
        
        
        var body = {
            sites: data
        }

        return http.post("/convertSites", body, header);
    }

    getWebsiteAnalytics(data) {
        
        var body = {
            name: data
        }
        return http.post("/webAnalytics", body, header);
    }

    getWebsiteForms(data) {
        var body = {
            site: data
        }
        return http.post("/websiteForms", body, header);
    }
}

export default new WebsiteService();