import React, { useState, useEffect } from "react";
import { Button, Card, Container, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { DesignService } from "../../services";
import downloadIcon from "../../assets/images/icons/file-download.svg";
import copyIcon from "../../assets/images/icons/copy-link.svg";
import deleteIcon from "../../assets/images/icons/red-delete.svg";
import axios from 'axios';
const DesignContainer = ({ objKey, url, name }) => {
    const [fileKey, setFileKey] = useState(objKey);
    const [fileUrl, setFileUrl] = useState(url);
    const [fileName, setFileName] = useState(name);
    const [visible, setVisible] = useState(true);

    const removeElement = async () => {
        setVisible((prev) => !prev);
        await DesignService.deleteDesign({ key: fileKey })
            .then(res => {
                console.log("Design deleted")
            }).catch(err => {
                console.log("Error deleting design: ", err);
            });
    };

    const handleDownload = async () => {
        axios({
            //url: `http://localhost:5000/api/design/downloadDesign?fileKey=${fileKey}&fileName=${fileName}`, // LocalHost
            url: `https://testapi.digitalrange.com/api/design/downloadDesign?fileKey=${fileKey}&fileName=${fileName}`, // Test
            method: 'GET',
            responseType: 'blob', // important
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((err) => {
            console.log(err);
        })
    };
      
    const truncateString = (str, maxLength) => {
        if (str.length <= maxLength) {
            return str;
        }
        const name = str.split(".")[0]
        const extension = str.split(".")[1]
        return str.slice(0, maxLength - 4) + '...' + name.slice(-2) + "." + extension;
    };

    var imageStyle = {
        backgroundImage: "url(\"" + fileUrl + "\")",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "200px",
    }

    return (
        <div>
            {url && visible ?
                <div className="designCard">
                    <div style={imageStyle}></div>
                    <div className="designCardInfo">
                        <p className="p-a designCardTitle">
                            {name.length > 16 ? truncateString(name, 16) : name}
                        </p>
                        <div className="designCardIcons">
                            <OverlayTrigger
                                delay={{ show: 300 }}
                                overlay={(props) => (
                                    <Tooltip {...props}>
                                        Download
                                    </Tooltip>
                                )}
                                placement="bottom"
                            >
                                <Button
                                    onClick={() => handleDownload()}
                                    className="btn bg-transparent"
                                >
                                    <img src={downloadIcon} width="18px" height="18px" alt="" />
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                delay={{ show: 300 }}
                                overlay={(props) => (
                                    <Tooltip {...props}>
                                        Copy Link
                                    </Tooltip>
                                )}
                                placement="bottom"
                            >
                                <Button
                                    onClick={() => navigator.clipboard.writeText(fileUrl)}
                                    className="btn bg-transparent"
                                >
                                    <img src={copyIcon} width="18px" height="18px" alt="" />
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                delay={{ show: 300 }}
                                overlay={(props) => (
                                    <Tooltip {...props}>
                                        Delete
                                    </Tooltip>
                                )}
                                placement="bottom"
                            >
                                <Button
                                    onClick={() => removeElement()}
                                    className="btn bg-transparent"
                                >
                                    <img src={deleteIcon} width="18px" height="18px" alt="" />
                                </Button>
                            </OverlayTrigger>

                        </div>
                    </div>
                </div>
                : false}
        </div>
    );
}

export default DesignContainer;