import React from "react";
import { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import {Button} from 'react-bootstrap'
import filterPen from "../../assets/images/icons/filter-pen.svg";

  const Option = (props) => {

    return (
      <>
        <components.Option {...props}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div>{props.children}</div>
            <div>
            <Button
              onClick={(e) => {e.stopPropagation(); props.selectProps.handleEditShow(props.value)}}
              className="edit-btn bg-transparent"
              id="edit-btn"
            >
              <img src={filterPen} width="14px" height="14px" className="penFilter" alt=""/>
            </Button>
            </div>
          </div>
        </components.Option>
      </>
    );
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      display: "inline-block",
    }),
    dropdownIndicator: () => ({
      display: "none",
      visibility: "hidden,",
    }),
    indicatorSeparator: () => ({
      display: "none",
      visibility: "hidden,",
    }),
    clearIndicator: () => ({
      padding: "0 4px",
    }),

    option: () => ({
      borderBottom: "1px solid #d3dae1",
      padding: "5px 12px",
      fontSize: "1.3rem",
      "&:hover #edit-btn": {
        // render the button
        visibility: "visible"

      }
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? "none" : "none",
      border: state.isFocused ? "none" : "none",
      minHeight: "20px",
      flexWrap: "nowrap"
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "8px 12px 8px 5px",
      backgroundColor: "#f4f5f9",
      borderRadius: "0 0 4px 4px",
      flexWrap:"nowrap"
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "1.5rem",
      fontWeight: "500",
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "1.5rem",
      fontWeight: "500",
      margin: "0",
      width: "10%",
      backgroundColor: state.isDisabled ? "white" : "#f4f5f9",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
  };
  
  
  const CustomSelect = ({
    options,
    changeOptionsData,
    fetchingData,
    onChange,
    addGroup,
    deleteGroup,
    handleDeleteShow,
    handleEditShow,
    onCreateOption,
    defaultValue

  }) => {
    return (
      <div>
        <CreatableSelect
            classNamePrefix="group-filters"
            styles={customStyles}
            menuPortalTarget={document.body}
            isSearchable
            isMulti
            options={options}
            components={{ Option }}
            fetchingData={fetchingData}
            changeOptionsData={changeOptionsData}
            onChange={onChange}
            addGroup={addGroup}
            deleteGroup={deleteGroup}
            handleDeleteShow={handleDeleteShow}
            handleEditShow={handleEditShow}
            onCreateOption={onCreateOption}
            defaultValue={defaultValue}
        />
      </div>
    );
  };
  export default CustomSelect;