import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserService, CanvaService } from '../../services';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export default function CanvaAuthorization() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access individual query parameters
  const user = queryParams.get('user');
  const state = queryParams.get('state');

  const uuid = useSelector((state) => state.isUUID);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const res = await UserService.getUser(uuid.uuid);
        setEmail(res.data.email);
      } catch (error) {
        // Handle any errors that occurred during user retrieval
        console.error(error);
      } finally {
        setIsLoading(false); // Set loading state to false
      }
    }

    fetchUserData();
  }, [uuid.uuid]);

  async function determineAuthorization(success) {
    try {
      if (success === "true") {
        await CanvaService.addCanvaID({ user: user, email: email });
      }
      window.location.href = `https://canva.com/apps/configured?success=${success}&state=${state}`;
    } catch (error) {
      // Handle any errors that occurred during authorization
      console.error(error);
    }
  }

  if (isLoading) {
    return null; // Show nothing while data is loading
  }else{
    return (
      <div className="login canvaContainer">
        <div className='canvaAuth'>
          <p>You're already logged in as <br /> {email}</p>
          <h4>Do you authorize Canva to access your account?</h4>
          <div className="canvaPerms">
            <p>Canva is requesting permission to export your designs to your DigitalRange account.</p>
          </div>
          <div className='canvaButtons'>
            <Button className="btn-a" onClick={() => determineAuthorization("true")}>Allow</Button>
            <Button className="btn-b" onClick={() => determineAuthorization("false")}>No Thanks</Button>
          </div>
          <p>Not you?
            <a
              style={{ cursor: 'pointer' }}
              //href="http://localhost:3000/logout?sso=true"
              //href={`https://test.digitalrange.com/logout?sso=true&user=${user}&state=${state}`}
              href={`/logout?sso=true&user=${user}&state=${state}`}
            ><u>Log out.</u>
            </a>
          </p>
        </div>
      </div>
    );
  }
}
