import React, { Component } from "react";
import { Container, Col, Row, Image, Table, Button } from "react-bootstrap";
import SelectSearch from "react-select-search";
import Modal from "react-modal";
import HeaderBox from "../generics/headerBox";
import { EmailService, WebsiteService } from "../../services";

import CircleLottie from "../generics/CircleLottie";
import ZendeskForm from "../generics/zendeskForm";
import activeUser from "./../../assets/images/icons/active_users.svg";
import pageViews from "./../../assets/images/icons/page-views.svg";
import openRate from "./../../assets/images/icons/open_rate.svg";
import campaignsSent from "./../../assets/images/icons/campaigns-sent.svg";
import blackFilter from "./../../assets/images/icons/filter-black.svg";
import { failedPopup } from "../adminPages/Popup";
import UserService from "../../services/userService";

/*
    User Homepage

    Separate from the user dashboard which contains links to other webapps

    This page hold the organization banner and all analytics for sites and email campaigns

    This page also contains zendesk integration and duda form submissions

*/

Modal.setAppElement(document.getElementById("user-dash"));

const customStyles = {
  content: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingTop: "70px",
    width: "100%",
    height: "95%",
    margin: "0",
  },
};
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasWebsite: false,
      hasEmail: false,
      hasForms: false,
      //Analytics
      views: 0,
      visits: 0,
      sendRate: 0,
      openRate: 0,
      visitRatio: 0,
      viewRatio: 0,
      sendRatio: 0,
      openRatio: 0,
      name: props.name,
      orgName: props.orgName,
      rawOrgName: props.rawOrgName,
      orgLogoLink: props.orgLogoLink,
      orgImageLink: props.orgImageLink,
      hasImage: props.hasImage,
      uuid: props.uuid,
      guid: props.guid,
      multiSite: false,
      email: this.props.email,
      sites: [],
      forms: [],
      sitesLoaded: false,
      formsLoaded: false,
      orgSelected: "",
      formSubmitLink: "",
      websiteToken: "",
      webGeneric: "",
      webLoaded: false,
      emailLoaded: false,
      columns: [],
      modalOpen: false,
      subLink: "",
      hasSubLink: false,
      accessToken: "",
    };
    this.searchOrgStats = this.searchOrgStats.bind(this);
    this.sites = this.sites.bind(this);
    this.emailStats = this.emailStats.bind(this);
    this.forms = this.forms.bind(this);
    this.token = this.token.bind(this);
    this.handleClick = this.handleClick.bind(this);
    //this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  // Calls Populating methods in parallel
  componentDidMount() {
    this.checkUser();
    this.sites();
    this.emailStats();
    // this.token();
  }

  // ALL METHODS CONTAINED HERE ARE USED FOR ON LOAD FUNCTIONALITY ------------------------------------------------------------------------

  // Handles whether a user has: (Website, Email, Forms)
  async checkUser() {
    await UserService.getUserMeta(this.state.uuid).then((res) => {
      if(res.data.hasEmail){
        this.setState({
          hasEmail: true
        })
      }
      if(res.data.hasWebsite){
        this.setState({
          hasWebsite: true
        })
      }
      if(res.data.hasForms){
        this.setState({
          hasForms: true
        })
      }
    })
  }

  // Handles website statistics
  async sites() {
    // Retrieves list of available sites from particular user
    await WebsiteService.getSiteList(this.state.email).then((res) => {
      this.setState({
        sites: res.data,
      });
    });

    // Determines whether site switcher should appear inside header
    if (Object.keys(this.state.sites).length > 1) {
      this.setState({
        multiSite: true,
      });
    }

    // Converts site shortcode to domain name
    await WebsiteService.convertSiteList(this.state.sites).then((res) => {
      this.setState({
        sites: res.data,
        sitesLoaded: true,
      });
    });

    // Grabs first site in list returned and displays it
    if (this.state.sites[0] !== undefined) {
      this.setState({ orgSelected: this.state.sites[0].value });
    }

    // Gets statistics for particular site
    this.searchOrgStats(this.state.orgSelected);
    this.forms(this.state.orgSelected);
  }

  // Handles email statistics
  async emailStats() {
    await EmailService.getEmailList(this.state.guid)
      .then((res) => {
        if (res.data) {
          //console.log("Email clocking 0");
          this.setState({
            sendRate: res.data.campaignsSent && res.data.campaignsSent,
            openRate: res.data.openRate,
            sendRatio: res.data.sendRatio,
            openRatio: res.data.openRatio,
            emailLoaded: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Handles website form submissions
  async token() {
    //Website Link
    await WebsiteService.getWebsite().then((res) => {
      this.setState({ webGeneric: res.data.key });
    });

    var args = {
      generic: this.state.webGeneric,
      email: this.state.email,
    };

    await WebsiteService.getWebsiteToken(args).then((res) => {
      this.setState({
        websiteToken: res.data,
        formSubmitLink:
          "https://website.digitalrange.com/home/dashboard/sites/" +
          this.state.orgSelected +
          "/form-responses?dm_sso=" +
          this.state.websiteToken,
      });
    });

    await EmailService.getSubLink(this.state.guid).then((res) => {
      if (res.data.length > 0) {
        this.setState({
          subLink: res.data,
          hasSubLink: true,
        });
      }
    });
  }

  async forms(org) {
    await WebsiteService.getWebsiteForms(org).then((res) => {
      this.setState({ formsLoaded: false });
      var preForms = [];
      preForms = res.data;
      var temp, temp2;
      var tempObj = {};
      var colList = [];
      var valList = [];
      var y, m, d;
      var final;
      var tempForms = [];
      if (preForms[0]) {
        for (var x of Object.keys(preForms[0].message)) {
          if (x !== "utm_campaign" && x !== "date") {
            colList.push(x);
          }
        }

        this.setState({ columns: colList });

        for (var i in preForms) {
          // Date
          temp = preForms[i].date.split("T")[0];
          temp2 = temp.split("-");
          y = temp2[0];
          m = temp2[1];
          d = temp2[2];

          final = m + "-" + d + "-" + y;

          tempObj.date = final;

          // Form Fields
          for (var j of Object.values(preForms[i].message)) {
            valList.push(j);
          }

          tempObj.one = valList[0];
          tempObj.two = valList[1];
          tempObj.three = valList[2];

          tempForms.push(tempObj);

          tempObj = {};
          valList = [];
        }
      }
      this.setState({
        forms: tempForms,
        formsLoaded: true,
      });
      this.setState({
        formSubmitLink:
          "https://website.digitalrange.com/home/dashboard/sites/" +
          org +
          "/form-responses?dm_sso=" +
          this.state.websiteToken,
      });
    });
  }

  async searchOrgStats(org) {
    await WebsiteService.getWebsiteAnalytics(org).then((res) => {
      
      if (res.data.viewsRatio !== null && res.data.visitsRatio !== null) {
        if (res.data.visitsRatio < 0)
          this.setState({
            visitRatio: "↓" + (res.data.visitsRatio * -1).toFixed(2),
          });
        else
          this.setState({
            visitRatio: "↑" + res.data.visitsRatio.toFixed(2),
          });

        if (res.data.viewsRatio < 0)
          this.setState({
            viewRatio: "↓" + (res.data.viewsRatio * -1).toFixed(2),
          });
        else
          this.setState({
            viewRatio: "↑" + res.data.viewsRatio.toFixed(2),
          });

        this.setState({
          views: res.data.views,
          visits: res.data.visits,
          webLoaded: true,
        });
      }
    });
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------

  handleClick(e) {
    e.preventDefault();
    this.setState({ modalOpen: true });
  }

  /*
    afterOpenModal() {
        console.log(this.state.subLink);
    }
    */

  closeModal(e) {
    e.preventDefault();
    this.setState({ modalOpen: false });
  }

  async getWeb() {
    await WebsiteService.getWebsite().then((res) => {
      this.setState({ webGeneric: res.data.key });
    });

    var args = {
      generic: this.state.webGeneric,
      email: this.state.email,
    };

    await WebsiteService.getWebsiteToken(args).then((res) => {
      if (res.data.name) {
        failedPopup("Error While getting Website");
      } else {
        window.open(
          "https://website.digitalrange.com/home/dashboard/sites/" +
            this.state.orgSelected +
            "/form-responses?dm_sso=" +
            res.data,
          "_blank"
        );
      }
    });
  }

  render() {
    return (
      <div className="user-dash">
        <HeaderBox
          name={this.state.name}
          hasImage={this.state.hasImage}
          orgLogoLink={this.state.orgLogoLink}
          orgImageLink={this.state.orgImageLink}
          orgName={this.state.orgName}
        />
        <Container className="main-container">
          <Modal
            className="sub-modal"
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal}
            contentLabel="subscriber-modal"
            style={customStyles}
          >
            <button
              className="close-modal"
              onClick={(e) => this.closeModal(e)}
            />
            <iframe title="email-sub" className="email-sub" src={this.state.subLink}></iframe>
          </Modal>
          <Row className="row-user-a">





            
            {this.state.hasWebsite ? 
              <div className="user-analytics">
                <div className="website">
                  <h1 className="h1-a">30 Day Website Analytics</h1>   
                  {this.state.sitesLoaded && this.state.multiSite && (
                    <SelectSearch
                      className="change-site"
                      options={this.state.sites}
                      value={this.state.orgSelected}
                      placeholder=" "
                      onChange={(e) => {
                        this.setState({ orgSelected: e });
                        this.searchOrgStats(e);
                        this.forms(e);
                      }}
                    />
                  )}
      
                </div>
                <div className="web-analytics">
                  <Col className="col-u">
                    <div className="d4">
                      <Image className="icon-1" src={activeUser} />{" "}
                      <p className="p-a">Unique Visitors</p>
                    </div>
                    {this.state.webLoaded ? (
                      <div>
                        <h2 className="h2-a">{this.state.visits}</h2>
                        <p className="greentext">
                          {this.state.visitRatio}% vs last month
                        </p>
                      </div>
                    ) : (
                      <CircleLottie />
                    )}
                  </Col>
                  <Col className="col-u">
                    <div className="d4">
                      <Image className="icon-1" src={pageViews} />{" "}
                      <p className="p-a">Page Views</p>
                    </div>
                    {this.state.webLoaded ? (
                      <div>
                        <h2 className="h2-a">{this.state.views}</h2>
                        <p className="greentext">
                          {this.state.viewRatio}% vs last month
                        </p>
                      </div>
                    ) : (
                      <CircleLottie />
                    )}
                  </Col>
                </div>
              </div>
            : false}







            {this.state.hasEmail ? 
              <div className="user-analytics">
                <div className="email">
                  <h1 className="h1-a s-l">30 Day Email Analytics</h1>
                </div>
                <div className="email-analytics">
                  <Col className="col-u">
                    <div className="d4">
                      <Image className="icon-1" src={campaignsSent} />{" "}
                      <p className="p-a">Campaigns Sent</p>
                    </div>
                    {this.state.emailLoaded ? (
                      <div>
                        <h2 className="h2-a">{this.state.sendRate}</h2>
                        <p className="greentext">
                          {this.state.sendRatio < 0
                            ? "↓"  + this.state.sendRatio * -1
                            : "↑"  + this.state.sendRatio}
                          % vs last month
                        </p>
                      </div>
                    ) : (
                      <CircleLottie />
                    )}
                  </Col>
                  <Col className="col-u">
                    <div className="d4">
                      <Image className="icon-1" src={openRate} />{" "}
                      <p className="p-a">Open Rate</p>
                    </div>
                    {this.state.emailLoaded ? (
                      <div>
                        <h2 className="h2-a">{this.state.openRate}%</h2>
                        <p className="greentext">
                          {this.state.openRatio < 0
                            ? "↓" + this.state.openRatio * -1
                            : "↑" + this.state.openRatio}
                          % vs last month
                        </p>
                      </div>
                    ) : (
                      <CircleLottie />
                    )}
                  </Col>
                  <Col className="col-btn add-sub">
                    {this.state.hasSubLink && (
                      <Button
                        className="btn-d btn-new btn-block"
                        onClick={(e) => this.handleClick(e)}
                      >
                        <i className="icon-Plus" />
                        Add Subscriber
                      </Button>
                    )}
                  </Col>
                </div>
              </div>
              : false}
          </Row>
          <Row className="row-user-b">
            <Col className="support-ticket">
              <div className="card-head">
                <h6 className="h6-a">What Can We Help With?</h6>
              </div>
              <ZendeskForm email={this.state.email} s />
            </Col>

            
            {this.state.hasWebsite ? 
              <Col className="form-submissions">
              <div>
                <div className="all-submissions card-head">
                  <h6 className="h6-a">Latest Form Submissions</h6>
                  <a
                    className="greentext new-tab"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.getWeb()}
                  >
                    View all Submissions
                  </a>
                </div>
                {this.state.formsLoaded ? (
                  <Table responsive className="s-table">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">{this.state.columns[0]}</th>
                        <th scope="col">{this.state.columns[1]}</th>
                        <th scope="col">{this.state.columns[2]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.forms.map((submission) => (
                        <tr>
                          <td key="date">{submission.date}</td>
                          <td key="one">{submission.one}</td>
                          <td key="two">{submission.two}</td>
                          <td key="three">{submission.three}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: 450}}>
                    <CircleLottie />
                  </div>
                )}
              </div>
            </Col>
            : false}
            
            {/*             */}

            {/*               */}
          </Row>
        </Container>
      </div>
    );
  }
}

export default HomePage;