import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Table,
  FormLabel,
  FormControl,
  Container,
} from "react-bootstrap";

import Select from "react-select";

export default function userView(props){
    return(
    <div className="admin-interior-view">
              <div className="actor-main-info">
                {/* ///////////////////////////////////////////////// */}
                
                <Row className="admin-row d-flex align-items-center">
                  <Col className="admin-col">
                    <h1>{props.userInfo.firstName + " " + props.userInfo.lastName}</h1>
                  </Col>

                  <Col className="admin-col">
                    <p className="d-flex">
                      {props.finalTime >= 7 ? (
                        <div className={"resend-cred"} onClick={props.handleResend}>
                          <i class="fa fa-repeat"></i>&nbsp; Resend Credentials{" "}
                        </div>
                      ) : (
                        <div className={"cred-sent"}>
                          <i class="fa fa-repeat"></i>&nbsp;credentials Sent{" "}
                        </div>
                      )}
                      &nbsp;
                      <span className="text-muted">{`(Last sent on ${props.userInfo.updatedAt})`}</span>
                    </p>
                  </Col>
                </Row>
                      
                <Row className="admin-row">
                  <Col className="admin-col">
                    <b className="info-title">First Name: </b>
                    <input
                      className="admin-input"
                      type="text"
                      value={props.userInfo.firstName}
                      
                      //defaultValue={userInfo.firstName}
                      onChange={(e) => {
                        e.persist();
                        props.setUserInfo((prevState) => ({
                          ...prevState,
                          firstName: e.target.value,
                        }));

                        props.handleUpdate();
                      }}
                      //   onChange={(e) => setFname(e.target.value)}
                    />
                  </Col>

                  <Col className="admin-col">
                    <b className="info-title">Last Name: </b>
                    <input
                      className="admin-input"
                      type="text"
                      value={props.userInfo.lastName}
                      //defaultValue={userInfo.lastName}
                      onChange={(e) => {
                        e.persist();
                        props.setUserInfo((prevState) => ({
                          ...prevState,
                          lastName: e.target.value,
                        }));
                        props.handleUpdate();
                      }}
                    />
                  </Col>
                </Row>
                {/*  */}
                <Row className="admin-row">
                  <Col className="admin-col">
                    <b className="info-title">Email: </b>
                    <input
                      className="admin-input"
                      type="email"
                      value={props.userInfo.email}
                      disabled
                    />
                  </Col>

                  <Col className="admin-col">
                    <b className="info-title">Organization: </b>
                    <Select
                      isClearable
                      styles={props.customStyles}
                      isDisabled={!props.canEdit}
                      classNamePrefix="org-select"
                      autoComplete="org select"
                      options={props.orgs}
                      value={props.org}
                      onChange={(e) => {
                        props.handleOrgChange(e);
                        props.handleUpdate();
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <Container fluid className="actor-services">
                <Button
                  className={props.userFlags.hasWebsite ? "btn-bc" : "btn-bd"}
                  onClick={(e) => props.handleButtonClick(e, "hasWebsite")}
                >
                  <i className="icon-user icon-w"></i>Website
                </Button>
                <Button
                  className={props.userFlags.hasEmail ? "btn-bc" : "btn-bd"}
                  onClick={(e) => props.handleButtonClick(e, "hasEmail")}
                >
                  <i className="icon-user icon-e"></i>Email
                </Button>
                <Button
                  className={props.userFlags.hasForms ? "btn-bc" : "btn-bd"}
                  onClick={(e) => props.handleButtonClick(e, "hasForms")}
                >
                  <i className="icon-user icon-f"></i>Forms
                </Button>
                <Button
                  className={props.userFlags.hasDesign ? "btn-bc" : "btn-bd"}
                  onClick={(e) => props.handleButtonClick(e, "hasDesign")}
                >
                  <i className="icon-user icon-d"></i>Design
                </Button>
                <Button
                  className={props.userFlags.hasFiles ? "btn-bc" : "btn-bd"}
                  onClick={(e) => props.handleButtonClick(e, "hasFiles")}
                >
                  <i className="icon-user icon-fl"></i>Files
                </Button>
                <Button
                  className={props.userFlags.hasClubchat ? "btn-bc" : "btn-bd"}
                  onClick={(e) => props.handleButtonClick(e, "hasClubchat")}
                >
                  <i className="icon-user icon-c"></i>ClubChat
                </Button>
                <Button
                  className={props.userFlags.hasEvents ? "btn-bc" : "btn-bd"}
                  onClick={(e) => props.handleButtonClick(e, "hasEvents")}
                >
                  <i className="icon-user icon-ev"></i>Events
                </Button>
                <Button
                  className={props.userFlags.hasDatabase ? "btn-bc" : "btn-bd"}
                  onClick={(e) => props.handleButtonClick(e, "hasDatabase")}
                >
                  <i className="icon-user icon-db"></i>Database
                </Button>
              </Container>
              {" "}
              
              {/*props.userFlags.hasEvents && (
                <div className="admin-service">
                  <b className="service-title">Events</b>
                  <div className="service-info">
                    <b className="info-title">Events Link:</b>
                    <input
                      className="admin-input"
                      type="text"
                      value={props.userInfo.eventLink}
                      //defaultValue={userInfo.eventLink}
                      onChange={(e) => {
                        e.persist();
                        props.setUserInfo((prevState) => ({
                          ...prevState,
                          eventLink: e.target.value,
                        }));
                        props.handleUpdate();
                      }}
                    />
                  </div>
                </div>
                    )*/}
            </div>
    )
}