/*
    Services utilized by facebook/tockify

    Includes: 

    getEventLink - retrieves event link from database
*/

import http from "../http-common";

class EventService {
    getEventLink(data) {
        return http.get("/EventLink", {
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY
              },
            params: {
                guid: data
            }
        });
    }
}

export default new EventService();