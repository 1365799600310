/*
    Setup configuration for axios.
    This files creates a base endpoint to which all other endpoints are appended.
    The flag "isDev" exists to allow a quick switch between the two API's (development/production)
*/

import axios from "axios";

var isDev = false;


export default axios.create({
    baseURL: isDev ? "http://localhost:8080/api/" : process.env.REACT_APP_SERVER_URL, 
    headers: {
        "Content-type": "application/json"
    }
});
