import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
//import { useSpring, animated } from 'react-spring';
import { Transition } from "react-transition-group";
import LoginLottie from "./generics/LoginLottie";

import LoginLogo from "../assets/images/New-DR-Waving-Flag.gif";
import DroneShot from "../assets/images/DR-BG-1.jpg";

import { Auth } from "aws-amplify";

//State management
import { useDispatch } from "react-redux";
import { login, setUser, setUUID } from "./../actions";
import crypto from "crypto";
import { AdminService } from "../services";
/*
  Login page
  Allows user to log in to application
  Utilizes cognito


*/
const ValidError = (props) => {
  return (
    <div className="h6-d bottom-space top-space">
      <p className="p-criteria">
        Your password must meet all the requirements below:
      </p>
      <p
        className="p-criteria"
        style={{ color: props.diffPass ? "#3aaf50" : "red" }}
      >
        &nbsp;&nbsp;&nbsp;Different than your previous password
      </p>
      <p
        className="p-criteria"
        style={{ color: props.eightChar ? "#3aaf50" : "red" }}
      >
        &nbsp;&nbsp;&nbsp;At least 8 characters
      </p>
      <p
        className="p-criteria"
        style={{ color: props.uppercaseChar ? "#3aaf50" : "red" }}
      >
        &nbsp;&nbsp;&nbsp;At least one uppercase character (A-Z)
      </p>
      <p
        className="p-criteria"
        style={{ color: props.lowercaseChar ? "#3aaf50" : "red" }}
      >
        &nbsp;&nbsp;&nbsp;At least one lowercase character (a-z)
      </p>
      <p
        className="p-criteria"
        style={{ color: props.numChar ? "#3aaf50" : "red" }}
      >
        &nbsp;&nbsp;&nbsp;At least one number (0-9)
      </p>
      <p
        className="p-criteria"
        style={{ color: props.specialChar ? "#3aaf50" : "red" }}
      >
        &nbsp;&nbsp;&nbsp;At least one special character (!@#$%…)
      </p>
    </div>
  );
};

export default function Login() {
  // Rerenders intercom widget
  window.IntercomLoad();

  // Redux
  const dispatch = useDispatch();

  //these need to be hashed
  // User info
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [resetCode, setCode] = useState("");

  // Synchronization flags
  const [forgotPass, setForgot] = useState(false);
  const [codeSent, setCodeSend] = useState(false);
  const [passwordReset, setReset] = useState(false);
  const [resetPass, setResetPass] = useState(false);

  const [lottieIn, setLottie] = useState(false); // Lottie animation
  const [incorrectLogin, isCorrect] = useState(true); // display Error messages
  const [visiblePass, isVisible] = useState(false); // Show/hide password
  const [visibleConfirm, isVisibleConfirm] = useState(false);
  const [errMsg, setErr] = useState(""); // Error message
  const [errVal, setErrVal] = useState(false);

  // Password Checks
  const [diffPass, setDiffPass] = useState(false);
  const [eightCharPass, setEightCharPass] = useState(false);
  const [uppercaseCharPass, setUppercaseCharPass] = useState(false);
  const [lowercaseCharPass, setLowercaseCharPass] = useState(false);
  const [numCharPass, setNumCharPass] = useState(false);
  const [specialCharPass, setSpecialCharPass] = useState(false);
  const [passCheck, setPassCheck] = useState(false);
  // Transition hooks
  const [inProp, setInProp] = useState(true);

  useEffect(() => {
    if (newPassword != password) {
      setDiffPass(true);
    } else {
      setDiffPass(false);
    }
    if (newPassword.match(".{8,}")) {
      setEightCharPass(true);
    } else {
      setEightCharPass(false);
    }
    if (newPassword.match("[A-Z].*")) {
      setUppercaseCharPass(true);
    } else {
      setUppercaseCharPass(false);
    }
    if (newPassword.match("[a-z].*")) {
      setLowercaseCharPass(true);
    } else {
      setLowercaseCharPass(false);
    }
    if (newPassword.match("[0-9].*")) {
      setNumCharPass(true);
    } else {
      setNumCharPass(false);
    }
    if (newPassword.match("[^A-Za-z0-9].*")) {
      setSpecialCharPass(true);
    } else {
      setSpecialCharPass(false);
    }
  }, [newPassword]);

  useEffect(() => {
    if (
      diffPass &&
      eightCharPass &&
      uppercaseCharPass &&
      lowercaseCharPass &&
      numCharPass &&
      specialCharPass
    ) {
      setPassCheck(true);
    } else {
      setPassCheck(false);
    }
  }, [
    diffPass,
    eightCharPass,
    uppercaseCharPass,
    lowercaseCharPass,
    numCharPass,
    specialCharPass,
  ]);

  useEffect(() => {
    updateIntercom();
  }, [resetPass, errMsg]);
  const duration = 200;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-out`,
    opacity: 0.5,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0.5 },
    exited: { opacity: 0.5 },
  };

  var userResult;
  //var errorMessage = "";

  function updateIntercom() {
    window.Intercom("update");
  }

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function validateReset() {
    return (
      (email.length > 0 || resetCode.length > 0) && password === passwordConfirm
    );
  }

  // put animation hook here
  async function handleSubmit(event) {
    event.preventDefault();
    setInProp(false);
    setLottie(true);
    isCorrect(true);

    // Call to Cognito
    try {
      await Auth.signIn(email, password).then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setResetPass(true);
        }
      });
      await Auth.currentAuthenticatedUser().then((user) => {
        dispatch(setUUID(user.attributes.sub));

        if (
          user.signInUserSession.accessToken.payload["cognito:groups"][0] ===
          "Users"
        ) {
          userResult = "user";
          let hmac = crypto
            .createHmac("sha256", process.env.REACT_APP_INTERCOM)
            .update(email)
            .digest("hex");
          localStorage.setItem("hmac", hmac);
          localStorage.setItem("email", email);
        }
        if (
          user.signInUserSession.accessToken.payload["cognito:groups"][0] ===
          "Admins"
        ) {
          userResult = "admin";
        }
      });

      dispatch(setUser(userResult));
      dispatch(login());

      window.location.reload();
    } catch (e) {
      setInProp(true);
      setLottie(false);
      isCorrect(false);
    }
  }
  // Reset New Password
  const newResetPassword = async (e) => {
    e.preventDefault();
    setInProp(false);
    setLottie(true);

    if (
      newPassword === passwordConfirm &&
      newPassword.match(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"
      )
    ) {
      await Auth.signIn(email, password).then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setResetPass(true);
          Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword, // the new password
            // OPTIONAL, the required attributes
            {
              email: user.challengeParam.userAttributes.email,
            }
          )
            .then((res) => {
              // at this time the user is logged in if no MFA required
              if (res.challengeName === undefined) {
                window.location.reload();
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    } else {
      setErrVal(true);
      // setErr("validation Error");
    }
  };

  // Reset Password Function
  async function resetPassword(event) {
    event.preventDefault();

    if (!codeSent) {
      try {
        await Auth.forgotPassword(email).then((res) => {
          if (res) {
            setCodeSend(true);
            setErr("");
          }
        });
      } catch (e) {
        if (e.name === "LimitExceededException")
          setErr(
            "Reset limited exceeded. Please try again later, or contact us through the green chat bubble for assistance."
          );
        else console.log(e.name);
      }
    } else {
      try {
        await Auth.forgotPasswordSubmit(email, resetCode, password).then(
          (res) => {
            setErr("");
            setReset(true);
            setErrVal(false);
          }
        );
      } catch (e) {
        console.log(e);
        if (e.name === "LimitExceededException")
          setErr(
            "Reset limited exceeded. Please try again later, or contact us through the green chat bubble for assistance."
          );
        else if (e.name === "CodeMismatchException")
          setErr(
            "Incorrect reset code. Please try again, or contact us through the green chat bubble for assistance."
          );
        else if (
          e.name === "InvalidParameterException" ||
          e.name === "InvalidPasswordException"
        )
          setErrVal(true);
        // setErr(
        //   "Password must be at least 8 characters in length and must contain at least one capitalized letter and one special character. (!, @, #, $, %, ^, &, *)"
        // );
        else console.log(e.name);
      }
    }
  }

  function handleForgot(e) {
    e.preventDefault();

    setPassword("");
    setForgot(!forgotPass);
    setReset(false);
    setCodeSend(false);
  }

  function handleVisiblePass(e) {
    e.preventDefault();
    isVisible(!visiblePass);
  }
  function handleVisibleConfirm(e) {
    e.preventDefault();
    isVisibleConfirm(!visibleConfirm);
  }

  // Login Page
  function returnLogin() {
    return (
      <div className="login">
        <div className="loginLeft">
          <img
            className="loginLogo"
            src={LoginLogo}
            alt="Logo"
            href="https://digitalrange.com"
          />
          <h1 className="h1-c"> Welcome Back</h1>
          {!incorrectLogin && (
            <p className="error">Incorrect email or password</p>
          )}

          <Transition in={inProp} timeout={500}>
            {(state) => (
              <Form
                className={`loginForm`}
                onSubmit={handleSubmit}
                style={{ ...defaultStyle, ...transitionStyles[state] }}
                noValidate
              >
                <FormGroup controlId="email">
                  <FormLabel>Email Address</FormLabel>
                  <FormControl
                    autoFocus
                    autoComplete="off"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      isCorrect(true);
                    }}
                    className={incorrectLogin ? "loginPass" : "loginIncorrect"}
                  />
                </FormGroup>
                <FormGroup controlId="password" className="showPass">
                  <FormLabel>Password</FormLabel>
                  <FormControl
                    autoComplete="off"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      isCorrect(true);
                    }}
                    type={visiblePass ? "text" : "password"}
                    className={incorrectLogin ? "loginPass" : "loginIncorrect"}
                  />
                  <Button
                    className={
                      !visiblePass ? "pass show-pass" : "pass hide-pass"
                    }
                    onClick={(e) => handleVisiblePass(e)}
                  />
                </FormGroup>
                <div className="form-btm">
                  <a 
                    className="link-forgot" 
                    href="/forgot-password"
                  >
                    Forgot Password?
                  </a>
                </div>

                <Button
                  variant="a"
                  className="btn-a btn-block"
                  data-toggle="button"
                  aria-pressed="true"
                  autoComplete="off"
                  disabled={!validateForm()}
                  type="submit"
                >
                  Login
                </Button>
              </Form>
            )}
          </Transition>
          {lottieIn && <LoginLottie className="lottieWrapper" />}
          <div className="loginBottom">
            <Button
              variant="b"
              href="https://www.digitalrange.com/signup"
              className="btn-b btn-block"
              data-toggle="button"
              aria-pressed="true"
            >
              Need an Account?
            </Button>
          </div>
          <h6 className="h6-b btm">© 2023 DigitalRange All Rights Reserved.</h6>
        </div>

        <div className="loginRight">
          <img className="loginImage" src={DroneShot} alt="droneShot" />
        </div>
      </div>
    );
  }

  // Forgot Password page
  function returnForgotPass() {
    return (
      <div className="login">
        <div className="loginLeft">
          <img
            className="loginLogo"
            src={LoginLogo}
            alt="Logo"
            href="https://digitalrange.com"
          />
          <Form
            className="loginForm"
            onSubmit={(e) => resetPassword(e)}
            noValidate
          >
            <h1 className="h1-c"> Forgot password</h1>
            {!codeSent && (
              <p className="p-c"> Enter your email to receive a reset code</p>
            )}

            {codeSent && (
              <p className="p-c"> Enter the code you received in your email</p>
            )}
            {errMsg !== "" && <p className="error bottom-space">{errMsg}</p>}
            <div>
              {!codeSent && (
                <FormGroup controlId="email">
                  <FormLabel>Email Address</FormLabel>
                  <FormControl
                    className="login-btm"
                    autoFocus
                    autoComplete="off"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              )}
              {codeSent && !passwordReset && (
                <FormGroup controlId="Code" className="showPass form-btm">
                  <FormLabel className="form-space">Recovery Code</FormLabel>
                  <FormControl
                    autoFocus
                    name="new-code"
                    id="new-code"
                    autoComplete="new-code"
                    value={resetCode}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <FormLabel className="form-space">New Password</FormLabel>
                  <FormControl
                    name="new-password"
                    id="new-password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={visiblePass ? "text" : "password"}
                  />
                  <FormLabel className="form-space">
                    Confirm New Password
                  </FormLabel>
                  <FormControl
                    name="new-password"
                    id="new-password"
                    autoComplete="new-password"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    type={visiblePass ? "text" : "password"}
                  />
                  <ValidError />
                  <Button
                    className={
                      !visiblePass ? "pass show-pass" : "pass hide-pass"
                    }
                    onClick={(e) => handleVisiblePass(e)}
                  />
                </FormGroup>
              )}
              {passwordReset && (
                <h3 className="text-center"> Password has been reset! </h3>
              )}

              {!passwordReset && (
                <Button
                  variant="a"
                  className="btn-a btn-block"
                  data-toggle="button"
                  aria-pressed="true"
                  autoComplete="off"
                  disabled={!validateReset()}
                  type="submit"
                >
                  Reset
                </Button>
              )}
            </div>
          </Form>
          <div className="form-btm">
            <Link className="link-forgot" onClick={(e) => handleForgot(e)}>
              Return to Login
            </Link>
          </div>
          <h6 className="h6-b btm">© 2023 DigitalRange All Rights Reserved.</h6>
        </div>
        <div className="loginRight">
          <img className="loginImage" src={DroneShot} alt="droneShot" />
        </div>
      </div>
    );
  }

  //          Reset password
  function returnResetPass() {
    return (
      <div className="login">
        <div className="loginLeft">
          <img
            className="loginLogo"
            src={LoginLogo}
            alt="Logo"
            href="https://digitalrange.com"
          />
          <Transition in={inProp} timeout={500}>
            {(state) => (
              <Form
                className="loginForm"
                style={{ ...defaultStyle, ...transitionStyles[state] }}
              >
                <h1 className="h1-c"> Reset password</h1>
                {!codeSent && <p className="p-c"> Enter your new password</p>}
                {errMsg !== "" && (
                  <p className="error bottom-space text-center">{errMsg}</p>
                )}
                <div>
                  <FormGroup controlId="password" className="showPass">
                    <FormLabel>Password</FormLabel>
                    <FormControl
                      autoComplete="off"
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                        isCorrect(true);
                      }}
                      type={visiblePass ? "text" : "password"}
                      className={passCheck ? "loginPass" : "loginIncorrect"}
                    />
                    <Button
                      className={
                        !visiblePass ? "pass show-pass" : "pass hide-pass"
                      }
                      onClick={(e) => handleVisiblePass(e)}
                    />
                  </FormGroup>
                  <FormGroup controlId="password" className="showPass">
                    <FormLabel>Confirm Password</FormLabel>
                    <FormControl
                      autoComplete="off"
                      value={passwordConfirm}
                      onChange={(e) => {
                        setPasswordConfirm(e.target.value);
                        isCorrect(true);
                      }}
                      type={visibleConfirm ? "text" : "password"}
                      className={
                        newPassword == passwordConfirm
                          ? "loginPass"
                          : "loginIncorrect"
                      }
                    />
                    <Button
                      className={
                        !visibleConfirm ? "pass show-pass" : "pass hide-pass"
                      }
                      onClick={(e) => handleVisibleConfirm(e)}
                    />
                  </FormGroup>

                  {/* {passwordReset && (
                    <h3 style={{ textAlign: "center" }}>
                      {" "}
                      Password has been reset!{" "}
                    </h3>
                  )} */}
                  <ValidError
                    diffPass={diffPass}
                    eightChar={eightCharPass}
                    uppercaseChar={uppercaseCharPass}
                    lowercaseChar={lowercaseCharPass}
                    numChar={numCharPass}
                    specialChar={specialCharPass}
                  />
                  <Button
                    variant="a"
                    className="btn-a btn-block"
                    data-toggle="button"
                    aria-pressed="true"
                    autoComplete="off"
                    disabled={
                      passCheck && newPassword == passwordConfirm ? false : true
                    }
                    onClick={(e) => newResetPassword(e)}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            )}
          </Transition>
          {lottieIn && <LoginLottie className="lottieWrapper" />}
          <div className="form-btm">
            <Link className="link-forgot" onClick={(e) => handleForgot(e)}>
              Return to Login
            </Link>
          </div>
          <h6 className="h6-b btm">© 2023 DigitalRange All Rights Reserved.</h6>
        </div>
        <div className="loginRight">
          <img className="loginImage" src={DroneShot} alt="droneShot" />
        </div>
      </div>
    );
  }

  // ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  if (forgotPass) {
    return returnForgotPass();
  }
  if (resetPass) {
    return returnResetPass();
  } else {
    return returnLogin();
  }

  /*
  if (!forgotPass) {
    return (
      <Transition in={inProp} timeout={500}>
        {state =>
          <ReturnLogin className={`fade fade-${state}`} />
        }
      </Transition>
    )
  } else {
    return (
      <ReturnForgotPass />
    )
  }
  */

  /*
  if (!forgotPass) {
    return (
      <ReturnLogin />
    )
  } else {
    return (
      <ReturnForgotPass />
    )
  }
  */
}

//style={{ ...defaultStyle, ...transitionStyles[state] }}
