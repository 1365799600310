/*
    Redux update actions
*/

export const login = () => {
  return {
    type: "LOG_IN",
  };
};

export const logout = () => {
  return {
    type: "LOG_OUT",
  };
};

export const setUser = (userType) => {
  return {
    type: "IS_USER",
    payload: userType,
  };
};

export const setUUID = (uuid) => {
  return {
    type: "IS_UUID",
    payload: uuid,
  };
};

export const setSelection = (user) => {
  return {
    type: "IS_SELECT",
    payload: user,
  };
};

// TODO
export const setUserSubmission = (user) => {
  return {
    type: "USER_SUBMISSION",
    payload: user,
  };
};

export const setCreateUser = (user) => {
  return {
    type: "USER_CREATION",
    payload: user,
  };
};

export const setCreateOrg = (org) => {
  return {
    type: "ORG_CREATION",
    payload: org,
  };
};
