import React, { useState } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Form,
} from "react-bootstrap";
import { Transition } from "react-transition-group";
import LoginLottie from "../generics/LoginLottie";

import { Auth } from "aws-amplify";

//State management
import { useDispatch } from "react-redux";
import { login, setUser, setUUID } from "../../actions";
import crypto from "crypto";
import CanvaAuthorization from "./canvaAuthorization";

/*
  Login page
  Allows user to log in to application
  Utilizes cognito
*/

export default function CanvaLogin(){
  // Redux
  const dispatch = useDispatch();

  //these need to be hashed
  // User info
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [lottieIn, setLottie] = useState(false); // Lottie animation
  const [incorrectLogin, isCorrect] = useState(true); // display Error messages
  const [visiblePass, isVisible] = useState(false); // Show/hide password

  // Transition hooks
  const [inProp, setInProp] = useState(true);

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0.5 },
    exited: { opacity: 0.5 },
  };

  var userResult;
  
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const duration = 200;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-out`,
    opacity: 0.5,
  };


  // put animation hook here
  async function handleSubmit(event) {
    event.preventDefault();
    setInProp(false);
    setLottie(true);
    isCorrect(true);

    // Call to Cognito
    try {
      await Auth.signIn(email, password).then((user) => {
        
      });
      await Auth.currentAuthenticatedUser().then((user) => {
        dispatch(setUUID(user.attributes.sub));

        if (
          user.signInUserSession.accessToken.payload["cognito:groups"][0] ===
          "Users"
        ) {
          userResult = "user";
          let hmac = crypto
            .createHmac("sha256", process.env.REACT_APP_INTERCOM)
            .update(email)
            .digest("hex");
          localStorage.setItem("hmac", hmac);
          localStorage.setItem("email", email);
        }
        if (
          user.signInUserSession.accessToken.payload["cognito:groups"][0] ===
          "Admins"
        ) {
          userResult = "admin";
        }
      });
      dispatch(setUser(userResult));
      dispatch(login());
      setLoggedIn(true);
      window.location.reload();
      
    } catch (e) {
      setInProp(true);
      setLottie(false);
      isCorrect(false);
      console.error(e);
    }
  }

  // Reset Password Function
  function handleForgot(e) {
    //loads in new window the forgot password page 
    //window.open("https://app.digitalrange.com/forgot-password");  // Prod
    window.open("/forgot-password")  // Test
    //window.open("http://localhost:3000/forgot-password");           // LocalHost
  }

  function handleVisiblePass(e) {
    e.preventDefault();
    isVisible(!visiblePass);
  }
  
  // Login Page
  function ReturnLogin() {
    return (     
      <div className="login">
        <div className="loginCanva">
          <h1 className="h1-c"> Welcome Back</h1>
          {!incorrectLogin && (
            <p className="error">Incorrect email or password</p>
          )}

          <Transition in={inProp} timeout={500}>
            {(state) => (
              <Form
                className={`loginForm`}
                onSubmit={handleSubmit}
                style={{ ...defaultStyle, ...transitionStyles[state] }}
                noValidate
              >
                <FormGroup controlId="email">
                  <FormLabel>Email Address</FormLabel>
                  <FormControl
                    autoFocus
                    autoComplete="off"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      isCorrect(true);
                    }}
                    className={incorrectLogin ? "loginPass" : "loginIncorrect"}
                  />
                </FormGroup>
                <FormGroup controlId="password" className="showPass">
                  <FormLabel>Password</FormLabel>
                  <FormControl
                    autoComplete="off"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      isCorrect(true);
                    }}
                    type={visiblePass ? "text" : "password"}
                    className={incorrectLogin ? "loginPass" : "loginIncorrect"}
                  />
                  <Button
                    className={
                      !visiblePass ? "pass show-pass" : "pass hide-pass"
                    }
                    onClick={(e) => handleVisiblePass(e)}
                  />
                </FormGroup>
                <div className="form-btm">
                  <a 
                    className="link-forgot" 
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleForgot(e)}>
                    Forgot Password?
                  </a>
                </div>

                <Button
                  variant="a"
                  className="btn-a btn-block"
                  data-toggle="button"
                  aria-pressed="true"
                  autoComplete="off"
                  disabled={!validateForm()}
                  type="submit"
                >
                  Login
                </Button>
              </Form>
            )}
          </Transition>
          {lottieIn && <LoginLottie className="lottieWrapper" />}
          <h6 className="h6-b btm">© 2023 DigitalRange All Rights Reserved.</h6>
        </div>
      </div>
    );
  }

  if (!loggedIn) {
    return ReturnLogin();
  } else { 
    return (
      <CanvaAuthorization />
    )
  }
}