import http from "../http-common";

class DesignService {
    getDesigns(data){
        return http.get("/design/getDesigns", {
            params: {
                user: data.uuid,
            }
        });
    }

    deleteDesign(data){
        return http.delete("/design/deleteDesign", {
            params: {
                key: data.key,
            }
        });
    }
}
export default new DesignService();