import React, { useState, useRef, useEffect} from "react";
import cookie from "react-cookies";
import ContentExplorer from "box-ui-elements/es/elements/content-explorer";
import { IntlProvider } from "react-intl";
import $ from "jquery";
import BoxService from "../../services/boxService";
import downloadIcon from "../../assets/images/icons/file-download.svg"
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default function BoxPage(props) {
  var afterModalCloseShareLinks = {}

  const [shareLinks, setShareLinks] = useState({})
  
  const folderId = localStorage.getItem("folderId");
  const accessToken = cookie.load("accessToken");
  const contentExplorerRef = useRef(null)

  useEffect(() => {
    
    //contentExplorerRef.current.addEventListener('create', (e) => test(e))
  }, [contentExplorerRef]);

  const checkDom = () => {
    // remove unecessary options in dropdown
    $(".be-share-access-select option").each(function () {
      if ($(this).val() === "collaborators" || $(this).val() === "company") {
        $(this).remove();
      }
    });
  };
  setInterval(() => {
    checkDom();
  }, 1000);

  function showDownloadTooltip(){
    $(".my-image").tooltip("dispose")
    $(".my-image").tooltip({placement: 'top', title: "Copy Direct Download Link"}).tooltip("show")
  }

  function updateDownloadTooltip(){
    $(".my-image").tooltip("dispose")
    $(".my-image").tooltip({placement: 'top', title: "Copied to Clipboard"}).tooltip("show")
  }

  

  // check to see if the share modal is open
  const targetNode = document.querySelector('.be-modal.be-modal-share');
  if (targetNode ) {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        // Modal is OPEN
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {      
          $(".be-modal-input-group input").each((index, input) => {
            if(input.value in shareLinks){
              input.value = shareLinks[input.value]
            }
            var modal = document.querySelector('.be-modal.be-modal-share');
            if(!modal.innerHTML.includes('file-download')){
              const modalBtns = document.querySelector('.be-modal-btns');
              const newButton = `<img class="my-image" src="${downloadIcon}" height=20 width=20>`
              modalBtns.querySelector('button[type="button"]').insertAdjacentHTML('beforebegin', newButton)
              const newButtonElement = document.querySelector('.my-image');

              $(".my-image").mouseenter(function()  {
                showDownloadTooltip();
              })

              newButtonElement.addEventListener('click', async function(event){
                updateDownloadTooltip();
                var fileId = input.value.split("=")[1];
                var file = await BoxService.getFile({fileID: fileId, accessToken: accessToken});
                var downloadUrl = file.data.shared_link.download_url;
                navigator.clipboard.writeText(downloadUrl);
              })
              newButtonElement.style.cursor = "pointer";
            }
          })
          
          // Modal is CLOSED
        } else if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
        }
      }
    });
    // observe the modal
    observer.observe(targetNode, {childList:true});
  }
  
  var requestIntercept = async (request) => {
    // this is to change the modal input
    // to be the correct link when the
    // modal is closing. 
    $(".be-modal-input-group input").each((index, input) => {
      if(input.value in afterModalCloseShareLinks){
        input.value = afterModalCloseShareLinks[input.value]
      }
    })
    
    // if > 1000, show pagination
    // gives us a file number limit of 1000/page
    if(request.hasOwnProperty("parsedUrl") && request.parsedUrl.api.includes("folder") && request.method.includes("get")){
      request.params.limit = 1000;
      request.maxContentLength = 0;
    }

    // reload the page after folder creation
    if (request.method.includes('post') && request.url.includes('folders')){
      window.location.reload();
    }

    return request;
  }

  var responseIntercept = async (response) => {

    // if a file (or multiple files) was uploaded, update the sharelink
    if(JSON.parse(response.request.response).entries){
      JSON.parse(response.request.response).entries.forEach(async element => {
        await BoxService.updateShareLink({id: element.id, accessToken: accessToken})
      });
    }
  
    try{ //<-- this try/catch is here to prevent an error when clicking off the modal multiple times
      const url = window.location.href.replace("/files", "");
      let Id = response.data.id;
      let shareLink = "";
      if (response.data.type === "file" && response.data.shared_link !== null) {
        var file = await BoxService.getFile({fileID: Id, accessToken: accessToken});
        var boxUrl = file.data.shared_link.url;
        var downloadUrl = file.data.shared_link.download_url;
        shareLink = url + "/file-preview?id=" + Id;
        afterModalCloseShareLinks = {...afterModalCloseShareLinks, [boxUrl]: shareLink}
        setShareLinks(shareLinks => ({...shareLinks, [boxUrl]: shareLink}))
        response.data.shared_link = {
          url: shareLink,
          download_url: null,
          vanity_url: null,
          effective_access: "open",
          vanity_name: null,
          effective_permission: "can_download",
          is_password_enabled: false,
          unshared_at: null,
          download_count: 0,
          preview_count: 0,
          access: "open",
          permissions: {
            can_preview: true,
            can_download: true,
          },
        };
      }
      else if (response.data.type === "folder" && response.data.shared_link !== null) {
        shareLink = url + "/folder-preview?id=" + Id;
        response.data.shared_link.url = shareLink;
      }
      return response;
    }catch(error){ 
    }
  };

  return (
    <div>
      {(!folderId || folderId === "null") ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              padding: "30px",
              textAlign: "center",
              backgroundColor: "#ffff",
              width: "300px",
              boxShadow: "1px 2px 5px #9bb8d5",
              borderRadius: "5px",
            }}
          >
            <h6 color="black">No folder found against this user.</h6>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh", paddingTop: "70px" }}>
          <IntlProvider locale="en">
            <ContentExplorer
              ref={contentExplorerRef}
              key='shared'
              requestInterceptor={requestIntercept}
              responseInterceptor={responseIntercept}
              rootFolderId={folderId.toString()}
              token={accessToken} 
            />
          </IntlProvider>
        </div>
      )}
    </div>
  );
}
