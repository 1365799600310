/*
    State information for user type designation

    This is used with cognito to determine if a user is an admin or not

*/

const userReducer = (state = { userType: "" }, action) => {
    switch (action.type) {
        case "IS_USER":
            return { ...state, userType: action.payload };
        default:
            return state;
    }
}

export default userReducer;