import React, { Component } from 'react';
import { Col, Form, FormControl, FormGroup, FormLabel, Table } from 'react-bootstrap';
//import UserDataService from "./../../services/service";
import { ZendeskService, UploadService } from '../../services';
import LoginLottie from "./LoginLottie";
import { successPopup } from '../adminPages/Popup';


/*
    Zendesk form submission component
*/ 

const acceptedFileTypes = [
    "png", "jpg", "gif", "pdf", "mp4", "txt", "rtf", "doc", "docx", "odt", "jpeg", "bmp", "tiff", "psd",
    "mp3", "wav", "aac", "flac", "m4a", "avi", "wmv", "mov", "mkv", "flv", "csv", "xls", "xlsx", "ppt",
    "pptx", "accdb", "zip", "rar", "tar", "gz", "7z", "ai", "eps", "svg", "indd", "f4v", "obj", "3ds"
]
var acceptedFileExtensions = [...acceptedFileTypes];
for (let i = 0; i < acceptedFileTypes.length; i++) {
    acceptedFileExtensions[i] = "." + acceptedFileTypes[i];
}

class ZendeskForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: this.props.email,
            ticketSubject: "",
            ticketDetails: "",
            fileUpload: [],
            lottieIn:false, // Lottie animation
            currentUploadSize: 0
        }
        this.validateForm = this.validateForm.bind(this);
        this.sendTicket = this.sendTicket.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    async sendTicket(e) {
        e.preventDefault();
        this.setState({lottieIn:true})
        var links = [];

        // Detects file attachments
        if (this.state.fileUpload !== null) {

            var count = 0;
            var sendData = new FormData(e.target);

            for (const z of Object.keys(this.state.fileUpload)) {
                count++;
                sendData.append('file', this.state.fileUpload[z], "user:" + this.state.email + "_zendesk_" + count);
            }

            // Uploads files to S3
            if (this.state.fileUpload.length > 1)
                await UploadService.sendTicketMulti(sendData).then(res => {
                    links = res.data;
                    console.log("Success");                 // These are in here for timing purposes
                })
            else
                await UploadService.sendTicketSingle(sendData).then(res => {
                    links.push(res.data);
                    console.log("Success");                 // These are in here for timing purposes
                })
        }

        // Ticket body setup
        var data = {
            ticketSubject: this.state.ticketSubject,
            ticketDetails: this.state.ticketDetails,
            email: this.state.email,
            links: links
        }

        // Send ticket
        ZendeskService.sendZendeskTicket(data);

        //alert("Ticket Sent!");
        successPopup("Ticket Sent!");
        

        // Reset
        this.setState({
            ticketSubject: "",
            ticketDetails: "",
            fileUpload: [],
            lottieIn:false
        });
    }

    removeItem(item, e) {
        e.preventDefault();
        var uploadTemp = [];
        for (var x = 0; x < this.state.fileUpload.length; x++) {
            //console.log(this.state.fileUpload[x]);
            if (this.state.fileUpload[x].name !== item.name) {
                uploadTemp.push(this.state.fileUpload[x]);
            }
        }
        this.setState({ fileUpload: uploadTemp });
    }

    // Validates form contents       /* TO DO */
    validateForm() {
        //return true;
        return this.state.ticketSubject.length > 0 && this.state.ticketDetails.length > 0
    }

    validateFileCount(){
        return this.state.fileUpload.length <= 5;
    }

    addTotalFileSize(size){
        var currentSize = this.state.currentUploadSize;
        currentSize += size;
        this.setState({currentUploadSize: currentSize})
    }

    render() {
        
        return (
            <Form className="zendesk-form" onSubmit={(e) => {e.preventDefault(); this.sendTicket(e)}} autoComplete="off" noValidate>
                <Col>
                    <FormGroup controlId="ticket-subject" >
                        <FormLabel></FormLabel>
                        <FormControl
                            placeholder="Subject of Ticket"
                            value={this.state.ticketSubject}
                            onChange={e => this.setState({ ticketSubject: e.target.value })}
                        />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup controlId="ticket-details">
                        <FormLabel></FormLabel>
                        <FormControl
                            className="detail-field"
                            as="textarea"
                            rows={5}
                            placeholder="Details of Ticket"
                            value={this.state.ticketDetails}
                            onChange={e => this.setState({ ticketDetails: e.target.value })}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <h6 className="h6-d">Any Additional Files? <label style={{fontSize:10}}>(limit: 5)</label></h6>
                    <div className="customFile zendesk-form-sec" >
                        <FormGroup controlId="zendesk-file" >
                            {this.state.fileUpload.length ?
                                <div className="upload-table">
                                    <FormLabel className="z-label-small" >
                                        <FormControl
                                            type="file"
                                            multiple
                                            className="custom-file-input"
                                            onChange={e => {
                                                this.setState({
                                                    fileUpload: e.target.files
                                                });
                                            }}
                                        />
                                    </FormLabel>
                                    <Table responsive className="z-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.from(this.state.fileUpload).map((file, index) => 
                                                index + 1 <= 5
                                                    ? file.size > 50000000
                                                        ? !acceptedFileTypes.includes(file.name.split(".")[file.name.split(".").length - 1])
                                                            ? (<tr onClick={e => this.removeItem(file, e)}>
                                                                <td key="Name"><del>{file.name}</del> <strong style={{color: "red"}}>File Cannot Exceed 50MB. Unsupported File Type: "{file.name.split(".")[file.name.split(".").length - 1]}".</strong></td>
                                                              </tr>)
                                                            : (<tr onClick={e => this.removeItem(file, e)}>
                                                                <td key="Name"><del>{file.name}</del> <strong style={{color: "red"}}>File Cannot Exceed 50MB.</strong></td>
                                                              </tr>)
                                                        : !acceptedFileTypes.includes(file.name.split(".")[file.name.split(".").length - 1])
                                                            ? (<tr onClick={e => this.removeItem(file, e)}>
                                                                <td key="Name"><del>{file.name}</del> <strong style={{color: "red"}}>Unsupported File Type: "{file.name.split(".")[file.name.split(".").length - 1]}".</strong></td>
                                                              </tr>)
                                                            : (<tr onClick={e => this.removeItem(file, e)}>
                                                                <td key="Name">{file.name}</td>
                                                              </tr>)  
                                                    : (<tr onClick={e => this.removeItem(file, e)}>
                                                        <td key="Name"><del>{file.name}</del> <strong style={{color: "red"}}>File count cannot exceed 5 files per ticket</strong></td>
                                                      </tr>)                                            
                                            )}  
                                        </tbody>
                                    </Table>
                                </div>
                                :
                                <FormLabel className="z-label" >
                                    <FormControl
                                        type="file"
                                        multiple
                                        className="custom-file-input"
                                        onChange={e => {
                                            this.setState({
                                                fileUpload: e.target.files
                                            });
                                        }}
                                        accept={acceptedFileExtensions}
                                    />
                                </FormLabel>
                            }
                        </FormGroup>
                        {this.state.lottieIn && <LoginLottie styleProp={{margin:'0', position: 'absolute', top: '-50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="lottieWrapper" />}
                    </div>
                </Col>
                {this.state.fileUpload.length !== 0 
                    ? Array.from(this.state.fileUpload).reduce((accumulator, file) => {return accumulator + file.size}, 0) > 250000000  
                        ? <p className='file-limit'>Total File Upload size cannot exceed 250MB</p>
                        : false
                    : false}
                <Col>
                    <button className="btn-a btn-block"   
                            aria-pressed="true" 
                            autoComplete="off" 
                            disabled={  !this.validateForm() || 
                                        this.state.lottieIn || 
                                        Array.from(this.state.fileUpload).reduce((accumulator, file) => {return accumulator + file.size}, 0) > 250000000 ||
                                        !Array.from(this.state.fileUpload).reduce((accumulator, file) => accumulator && acceptedFileTypes.includes(file.name.split(".")[file.name.split(".").length - 1]), true) ||
                                        !this.validateFileCount()
                                    } 
                            type="submit">
                        Submit
                    </button>
                </Col>
            </Form>
        )
    }
}

export default ZendeskForm;