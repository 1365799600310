/*
    Services utilized by campaignMonitor 

    Includes: 

    getEmailLink - gets tokenized link per user. Used to embed session into site
    getEmailInfo - gets clientID for email integration
    getSubLink   - returns subscriber link
    getEmailList - gets analytics on 30/60 day email campaigns
*/

import http from "../http-common";

const header = {
    headers: {
      'apiKey': process.env.REACT_APP_API_KEY
    }
  }

class EmailService { 
    getEmailLink(data) {
        return http.get("/EmailLink", {
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY
            },
            params: {
                email: data.email,
                guid: data.guid,
                userType: data.userType
            }
        });
    } // TODO

    getEmailInfo(data) {
        return http.get("/EmailInfo", {
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY
            },
            params: {
                guid: data
            }
        })
    }

    getSubLink(data) {
        return http.get("/SubLink", {
            headers: {
                'apiKey': process.env.REACT_APP_API_KEY
            },
            params: { guid: data }
        });
    }

    getEmailList(data) {
        var body = {
            guid: data
        }
        return http.post("/EmailList", body, header);
    }
}

export default new EmailService();