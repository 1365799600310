import React, { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
//import { useSpring, animated } from 'react-spring';
import { Transition } from "react-transition-group";
import LoginLottie from "./generics/LoginLottie";

import LoginLogo from "../assets/images/New-DR-Waving-Flag.gif";
import DroneShot from "../assets/images/DR-BG-1.jpg";

import { Auth } from "aws-amplify";

//State management
import { useDispatch } from "react-redux";
import { login, setUser, setUUID } from "./../actions";
import crypto from "crypto";


const ValidError = () => {
    return (
      <p className="h6-d bottom-space top-space">
        <b>Your password must meet all the requirements below:</b>
        <br />
        &nbsp;&nbsp;&nbsp;Different than your previous password
        <br />
        &nbsp;&nbsp;&nbsp;At least 8 characters
        <br />
        &nbsp;&nbsp;&nbsp;At least one uppercase character (A-Z)
        <br />
        &nbsp;&nbsp;&nbsp;At least one lowercase character (a-z)
        <br />
        &nbsp;&nbsp;&nbsp;At least one number (0-9)
        <br />
        &nbsp;&nbsp;&nbsp;At least one special character (!@#$%…)
      </p>
    );
  };
export default function ForgotPassword() {
    // Rerenders intercom widget
  window.IntercomLoad();

  // Redux
  const dispatch = useDispatch();

  //these need to be hashed
  // User info
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [resetCode, setCode] = useState("");

  // Synchronization flags
  const [forgotPass, setForgot] = useState(false);
  const [codeSent, setCodeSend] = useState(false);
  const [passwordReset, setReset] = useState(false);
  const [resetPass, setResetPass] = useState(false);

  const [lottieIn, setLottie] = useState(false); // Lottie animation
  const [incorrectLogin, isCorrect] = useState(true); // display Error messages
  const [visiblePass, isVisible] = useState(false); // Show/hide password
  const [visibleConfirm, isVisibleConfirm] = useState(false);
  const [errMsg, setErr] = useState(""); // Error message
  const [errVal, setErrVal] = useState(false);


  function validateReset() {
    if(password !== passwordConfirm){
      setErr(
        "Your passwords must match."
      )
    }
    return (
      (email.length > 0 || resetCode.length > 0) && password === passwordConfirm
    );
  }


  // Reset Password Function
  async function resetPassword(event) {
    event.preventDefault();
    const emailRegex = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(!emailRegex.test(email)){
        setErr(
            "Not a valid email address."
        );
    }else{
        setErr(
            ""
        );
        if (!codeSent) {
            try {
              await Auth.forgotPassword(email).then((res) => {
                if (res) {
                  setCodeSend(true);
                  setErr("");
                }
              });
            } catch (e) {
              if (e.name === "LimitExceededException")
                setErr(
                  "Reset limited exceeded. Please try again later, or contact us through the green chat bubble for assistance."
                );
            }
          } else {
            if(password === passwordConfirm){
              setErr(
                ""
              );
            }else{
              setErr(
                "Your passwords must match."
              );
            }

            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (!passwordRegex.test(password)){
              setErr(
                "Password must meet minimum requirements!"
              );
            }

            
            try {
              await Auth.forgotPasswordSubmit(email, resetCode, password).then(
                (res) => {
                  setErr("");
                  setReset(true);
                  setErrVal(false);
                }
              );
            } catch (e) {
              if (e.name === "LimitExceededException")
                setErr(
                  "Reset limited exceeded. Please try again later, or contact us through the green chat bubble for assistance."
                );
              else if(resetCode.length < 1)
                  setErr(
                    "Recovery Code cannot be left blank."
                  )
              else if (e.name === "CodeMismatchException")
                setErr(
                  "Incorrect reset code. Please try again, or contact us through the green chat bubble for assistance."
                );
              else if (
                e.name === "InvalidParameterException" ||
                e.name === "InvalidPasswordException"
              )
                setErrVal(true);
            }
          }
    }
    
    
  }

  function handleForgot(e) {
    e.preventDefault();

    setPassword("");
    setForgot(!forgotPass);
    setReset(false);
    setCodeSend(false);
  }

  function handleVisiblePass(e) {
    e.preventDefault();
    isVisible(!visiblePass);
  }
  function handleVisibleConfirm(e) {
    e.preventDefault();
    isVisibleConfirm(!visibleConfirm);
  }

    return (
      <div className="login">
        <div className="loginLeft">
          <img
            className="loginLogo"
            src={LoginLogo}
            alt="Logo"
            href="https://digitalrange.com"
          />
          <Form
            className="loginForm"
            onSubmit={(e) => resetPassword(e)}
            noValidate
          >
            {<h1 className="h1-c"> Forgot password</h1>}

            {!codeSent && (
              <p className="p-c"> Enter your email to receive a reset code</p>
            )}

            {/*codeSent && !passwordReset && <h1 className="h1-c"> A code will be sent to the email address if there is one associated with an account.</h1>*/}


            
            {/*codeSent && !passwordReset && (
              <p className="p-c"> Enter the code you received in your email</p>
            )*/}

            {codeSent && !passwordReset && (
              <p className="p-c"> A code will be sent to the email address if there is one associated with an account.</p>
            )}

            {errMsg !== "" && <p className="error bottom-space">{errMsg}</p>}
            <div>
              {!codeSent && (
                <FormGroup controlId="email">
                  <FormLabel>Email Address</FormLabel>
                  <FormControl
                    className="login-btm"
                    autoFocus
                    autoComplete="off"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              )}
              {codeSent && !passwordReset && (
                <FormGroup controlId="Code" className="showPass form-btm">
                  <FormLabel className="form-space">Recovery Code</FormLabel>
                  <FormControl
                    autoFocus
                    name="new-code"
                    id="new-code"
                    autoComplete="new-code"
                    value={resetCode}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <FormLabel className="form-space">New Password</FormLabel>
                  <FormControl
                    name="new-password"
                    id="new-password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={visiblePass ? "text" : "password"}
                  />
                   <Button
                    className={
                      !visiblePass ? "pass show-pass" : "pass hide-pass"
                    }
                    onClick={(e) => handleVisiblePass(e)}
                  />
                  <FormLabel className="form-space">
                    Confirm New Password
                  </FormLabel>
                  <FormControl
                    name="new-password"
                    id="new-password"
                    autoComplete="new-password"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    type={visibleConfirm ? "text" : "password"}
                  />
                  <Button
                    className={
                      !visibleConfirm ? "pass show-pass" : "pass hide-pass"
                    }
                    onClick={(e) => handleVisibleConfirm(e)}
                  />
                  <ValidError />
                  {/*<Button
                    className={
                      !visiblePass ? "pass show-pass" : "pass hide-pass"
                    }
                    onClick={(e) => handleVisiblePass(e)}
                  />*/}
                </FormGroup>
              )}
              {passwordReset && (
                <h3 className="text-center"> Password has been reset! </h3>
              )}

              {!passwordReset && (
                <Button
                  variant="a"
                  className="btn-a btn-block"
                  data-toggle="button"
                  aria-pressed="true"
                  autoComplete="off"
                  disabled={!validateReset}
                  type="submit"
                >
                  Reset
                </Button>
              )}
            </div>
          </Form>
          <div className="form-btm">
            <a 
              className="link-forgot" 
              href="/"
            >
              Return to Login
            </a>
          </div>
          <h6 className="h6-b btm">© 2023 DigitalRange All Rights Reserved.</h6>
        </div>
        <div className="loginRight">
          <img className="loginImage" src={DroneShot} alt="droneShot" />
        </div>
      </div>
    );
  

  

  // ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  
    
  
}

//style={{ ...defaultStyle, ...transitionStyles[state] }}
