/*
    Unifies all reducers

    REDUX

*/

import loggerReducer from "./isLogged";
import userReducer from "./isUser";
import uuidReducer from "./uuidReducer";
import selectionReducer from "./selectionReducer";
import createOrgReducer from "./createOrgReducer";
import createUserReducer from "./createUserReducer";
import boxReducer from "./boxReducer";
import { combineReducers } from "redux";


const rootReducer = combineReducers({
  isLogged: loggerReducer,
  isUser: userReducer,
  isUUID: uuidReducer,
  isSelect: selectionReducer,
  createOrg: createOrgReducer,
  createUser: createUserReducer,
  boxToken: boxReducer,
});

export default rootReducer;
