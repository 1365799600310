import React, { Component } from "react";
import ContentExplorer from "box-ui-elements/es/elements/content-explorer";
import { IntlProvider } from "react-intl";
//import getboxToken from "../../services/boxService";
import BoxService from "../../services/boxService";
import Logo from '../../assets/images/New-DR-Waving-Flag.gif';

class FolderPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      folderId: "",
    };
  }

  componentDidMount() {
    this.setState({ folderId: window.location.search.replace("?id=", "") });

    //getboxToken().then((res) => {
    BoxService.getBoxToken().then((res) => {
      this.setState({ token: res.data.access_token });
    });
  }
  responseIntercept = (response) => {
    let Id = response.data.id;
    let shareLink = "";
    if (response.data.type === "file") {
      shareLink = "http://localhost:3000/file-preview?id=" + Id;
    } else if (response.data.type === "folder") {
      shareLink = "http://localhost:3000/folder-preview?id=" + Id;
    }

    if (response.data.shared_link) {
      response.data.shared_link.url = shareLink;
    } else {
      response.data.share_link = { url: shareLink };
    }
    return response;
  };

  render() {
    return (
      <div style={{ height: "100vh" }}>
        {this.state.token !== "" && this.state.folderId !== "" && (
          <IntlProvider locale="en">
            <ContentExplorer
              logoUrl={Logo}
              responseInterceptor={this.responseIntercept}
              rootFolderId={this.state.folderId}
              token={this.state.token}
              canShare={false}
              canDelete={false}
              canRename={false}
              canUpload={false}
              canCreateNewFolder={false}
            />
          </IntlProvider>
        )}
      </div>
    );
  }
}

export default FolderPreview;
