import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import emailError from "../../assets/images/emailError.png";
import { EmailService } from "../../services";

/*
    Email page, contains campaignMonitor

    Tokenized link is retrieved from middleware and is iframed on page

    
*/

export default function EmailPage(props) {
  const [emailLink, setLink] = useState("a");
  const [hasLoaded, setLoaded] = useState(false);

  useEffect(() => {
    async function getData() {
      var data = {
        email: props.email,
        guid: props.guid,
        userType: props.usertype,
      };

      await EmailService.getEmailLink(data)
        .then((res) => {
          setLink(res.data);
          setLoaded(true);
        })
        .catch((err) => {
          console.log(err,'===========error'); 
        });
    }

    getData();
  }, []);

  return (
    <div className="iframe-holder">
      {emailLink === "" && (
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={emailError} alt="email-error" width={"80%"} />
        </div>
      )}
      {hasLoaded && (
        <Iframe
          className="sso-page"
          src={emailLink}
          height="100vh"
          width="100vw"
        />
      )}
    </div>
  );
}

//
