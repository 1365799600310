import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, NavDropdown } from "react-bootstrap";
import Logo from "../../assets/images/New-DR-Waving-Flag.gif";
import dropDown from "../../assets/images/icons/up-arrow.svg";
import cookie from "react-cookies";
//import UserDataService from "./../../services/service";
import {
  UserService,
  WebsiteService,
  EventService,
  FormService,
  FileService,
} from "../../services";
import { failedPopup } from "../adminPages/Popup";
import { ToastContainer } from "react-toastify";
import DesignModal from '../DesignModal';

/*
    Header block for user homepage

    This contains the links to other webapps, is the main bar on the top portion of page
*/

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toUpperCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasForms: false,
      hasFiles: false,
      hasEmail: false,
      hasWebsite: false,
      hasDesign: false,
      hasClubChat: false,
      hasEvents: false,
      uuid: props.uuid,
      guid: props.guid,
      websiteLink: "",
      formsLink: "",
      eventLink: "",
      webGeneric: "",
      email: props.email,
      viewLink: "",
      uploadLink: "",
      isOpen: false,
      designDropdown: false,
      modal: false,
    };
    this.getUser = this.getUser.bind(this);
    this.getWeb = this.getWeb.bind(this);
    this.getForms = this.getForms.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.getEventLink = this.getEventLink.bind(this);

    // design hub
    this.openCanva = this.openCanva.bind(this);
    this.showDesignDropdown = this.showDesignDropdown.bind(this);
    this.hideDesignDropdown = this.hideDesignDropdown.bind(this);
  }

  componentDidMount() {
    this.getUser();
    this.getUserData();
  }

  openCanva = () => {
    //window.open('https://www.canva.com/', '_blank', 'nopener,noreferrer');
    const newWindow = window.open('https://www.canva.com/', '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null // open a tab instead of a new window
  }

  showDesignDropdown = () => {
    this.setState({ designDropdown: true });
  }
  hideDesignDropdown = () => {
    this.setState({ designDropdown: false });
  }
  showModal = (e) => {
    e.stopPropagation();
    this.setState({ modal: true });
  }
  hideModal = () => {
    this.setState({ modal: false });
  }
  ////////////////////  USER POPULATON ////////////////////

  async getUser() {
    await UserService.getUserMeta(this.state.uuid).then((res) => {
      if (res.data.hasForms === 1) this.setState({ hasForms: true });

      if (res.data.hasFiles === 1) this.setState({ hasFiles: true });

      if (res.data.hasEmail === 1) this.setState({ hasEmail: true });

      if (res.data.hasWebsite === 1) this.setState({ hasWebsite: true });

      if (res.data.hasDesign === 1) this.setState({ hasDesign: true });

      if (res.data.hasClubchat === 1) this.setState({ hasClubChat: true });

      if (res.data.hasEvents === 1) this.setState({ hasEvents: true });
    });

    // this.getWeb();
    this.getForms();
    this.getFiles();
    this.getEventLink();
  }
  async getUserData() {
    await UserService.getUser(this.state.uuid).then((res) => {
      localStorage.setItem("folderId", res.data.folderId);
      if (!cookie.load("accessToken")) {
        cookie.save("accessToken", res.data.access_token, {
          path: "/",
          maxAge: res.data.access_token_expiry,
        });
      }
    });
  }
  //

  ////////////////////  API POPULATON ////////////////////

  async getWeb() {
    if (this.state.hasWebsite) {
      await WebsiteService.getWebsite().then((res) => {
        this.setState({ webGeneric: res.data.key });
      });

      var args = {
        generic: this.state.webGeneric,
        email: this.state.email,
      };

      await WebsiteService.getWebsiteToken(args).then((res) => {
        if (res.data.name) {
          failedPopup('Error While getting Website')
        } else {
          window.open(
            `https://website.digitalrange.com/home?dm_sso=${res.data}`,
            "_blank"
          );
        }

        // this.setState({
        //   websiteLink:
        //     "https://website.digitalrange.com/home?dm_sso=" + res.data,
        // });
      });
    }
  }

  async getForms() {
    if (this.state.hasForms) {
      await FormService.getFormsLink(this.state.guid).then((res) => {
        this.setState({ formsLink: res.data });
      });
    }
  }

  async getFiles() {
    if (this.state.hasFiles) {
      await FileService.getFilesLink(this.state.guid).then((res) => {
        this.setState({
          viewLink: "https://longdrive.box.com/s/" + res.data.folderID,
          uploadLink: "https://longdrive.app.box.com/f/" + res.data.uploadID,
        });
      });
    }
  }

  async getEventLink() {
    if (this.state.hasEvents) {
      await EventService.getEventLink(this.state.guid).then((res) => {
        this.setState({ eventLink: res.data });
      });
    }
  }

  render() {
    // console.log(this.state.formsLink, "Form Links...");
    const { modal } = this.state;
    return (
      <div>
        <header className="header">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Logo" href="*/" />
            </Link>
          </div>
          <nav className="header-nav">
            <ul className="menu">
              {this.state.hasWebsite && (
                <li>
                  <a onClick={() => this.getWeb()}>WEBSITE</a>
                </li>
              )}
              {this.state.hasEmail && (
                <li>
                  <Link to="/email" usertype="">
                    EMAIL
                  </Link>
                </li>
              )}
              {this.state.hasForms && (
                <li>
                  <a
                    href={this.state.formsLink}
                    target={this.state.formsLink === "/forms" ? "" : "_blank"}
                    rel="noopener noreferrer"
                  >
                    FORMS
                  </a>
                </li>
              )}{" "}
              {this.state.hasDesign && (
                <li>
                  <NavDropdown
                      className="navDD"
                      title="DESIGN"
                      onClick={ this.openCanva }
                      onMouseEnter={ this.showDesignDropdown }
                      onMouseLeave={ this.hideDesignDropdown }
                      show={this.state.designDropdown}
                    >
                      <NavDropdown.Item eventKey="design" className="navDD-item" onClick={this.showModal}>MY DESIGNS</NavDropdown.Item>
                    </NavDropdown>
                </li>
              )}
              {this.state.hasClubChat && (
                <li>
                  <a
                    href="https://connect.digitalrange.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CLUBCHAT
                  </a>
                </li>
              )}
              {this.state.hasEvents && (
                <li>
                  <a
                    href={this.state.eventLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EVENTS
                  </a>
                </li>
              )}
              {this.state.hasFiles && (
                <li>
                  <Link to="/files">FILES</Link>
                </li>
              )}
              <li>
                <Dropdown className="headerDD">
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                    bsPrefix="dropdown-toggle"
                  >
                    <div className="dropDown" id={dropDown}>
                      <img
                        id="custom-drop-2"
                        className="dd"
                        src={dropDown}
                        alt="dropdown"
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu as={CustomMenu} className="menuDD">
                    <Dropdown.Item
                      eventKey="1"
                      href="http://help.digitalrange.com/en/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      HELP CENTER
                    </Dropdown.Item>
                    <Dropdown.Divider className="ddDivide" />
                    <Dropdown.Item
                      eventKey="2"
                      href="https://www.digitalrange.com/creative-hub"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      CREATIVE HUB
                    </Dropdown.Item>
                    <Dropdown.Divider className="ddDivide" />
                    <Dropdown.Item eventKey="3" as={Link} to="/Logout">
                      LOGOUT
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </nav>
          <ToastContainer hideProgressBar style={{ width: "400px" }} />
        </header>
        <DesignModal showModal={modal} handleClose={this.hideModal} />
      </div>
    );
  }
}

export default Header;
