import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AdminHomePage from "../adminPages/adminHomePage";
import EmailPage from "../userPages/emailPage";
import Logout from "../Logout";
import AdminHeader from "../pageOverlay/adminHeader";
import CreateUser from "../adminPages/createUser";
import CreateOrg from "../adminPages/createOrg";

//import UserDataService from "./../../services/service";
import { UserService } from "../../services";
import BoxPage from "../userPages/boxPage";

/*
    Renders user header and selected page to DOM

    Used as a switcher between different services while keeping header visible
*/

export class AdminDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: props.uuid,
      guid: "",
      email: "",
      orgName: "Long Drive Agency",
      renderChildren: false,
    };
  }

  async componentDidMount() {
    await UserService.getUser(this.state.uuid).then((res) => {
      localStorage.setItem("folderId", res.data.folderId);
      this.setState({
        guid: res.data.guid,
        email: res.data.email,
        renderChildren: true,
      });
    });

    //console.log("Hey!");
    //Put orgname get here
  }

  render() {
    return (
      <>
        {this.state.renderChildren && (
          <div>
            <AdminHeader
              uuid={this.state.uuid}
              guid={this.state.guid}
              email={this.state.email}
            />

            <Switch>
              <Route path="/CreateOrg" exact>
                <CreateOrg />
              </Route>
              <Route path="/CreateUser" exact>
                <CreateUser />
              </Route>
              <Route path="/email" exact>
                <EmailPage
                  email={this.state.email}
                  guid={this.state.guid}
                  usertype="admin"
                />
              </Route>
              <Route path="/files" exact>
                <BoxPage orgName={this.state.orgName} />
              </Route>
              <Route path="/Logout" exact>
                <Logout />
              </Route>
              <Route>
                <Redirect to="/" />
                <AdminHomePage orgName={this.state.orgName} />
              </Route>
            </Switch>
          </div>
        )}
      </>
    );
  }
}

export default AdminDash;
