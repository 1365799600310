/*
    Configuration for AWS services.
    This is utilized by cognito and S3
*/

const config = {
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_ABipDuNWj",
        APP_CLIENT_ID: "3pnik2tdpk184gltsq5g1epp1i",
        IDENTITY_POOL_ID: "us-east-1:20944f3d-1ec7-4610-9dad-5c34c0348574"
    }
};

export default config;