import React from 'react';
import AdminRight from './adminRight';
import AdminLeft from './adminLeft';

/*
    Contains left and right pane
*/

function AdminHomePage() {
    const adminRightRef = React.useRef(null);

    return (
        <div className="admin-dash admin-grid" >
            
            <AdminLeft adminRightRef={adminRightRef}/>
            <AdminRight adminRightRef={adminRightRef}/>
        </div>
    )
}

export default AdminHomePage;