/*
    Services utilized by S3 & zendesk

    Includes: 

    sendOrgImages    - sends images to be used as org icon and cover photo
    sendTicketSingle - sends zendesk ticket with single file attachment
    sendTicketMulti  - sends zendesk ticket with multiple file attachments
*/

import http from "../http-common";

const header = {
  headers: {
    'apiKey': process.env.REACT_APP_API_KEY
  }
}

class UploadService {
  async sendOrgLogo(data) {
    try {
      return http.post("/document/uploadLogo", data, header);
    } catch (error) {
      return error.message;
    }
  }
  async sendOrgBack(data) {
    try {
      return http.post("/document/uploadBack", data, header);
    } catch (error) {
      return error.message;
    }
  }

  sendTicketSingle(data) {
    return http.post("/document/uploadSingle", data, header).catch((err) => {
      console.log(err);
    });
  }

  sendTicketMulti(data) {
    return http.post("/document/uploadMulti", data, header).catch((err) => {
      console.log(err);
    });
  }
}

export default new UploadService();
