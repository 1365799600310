import http from "../http-common";

class CanvaService {
    redirect(data){
        return http.get("/redirect", {
            params: {
              state: data.state,
              success: data.success,
            },
        });
    }

    addCanvaID(data){
        return http.post("/canva/addCanvaID", data);
    }

    verify(data){
        return http.get("/canva/verify", {
            headers: {
                "x-canva-timestamp": data.timestamp,
                "x-canva-signatures": data.sigs,
            }
        });
    }
}
export default new CanvaService();