import React from "react";
import formError from "../../assets/images/formsError.png";

/*
    Opens 123Forms session inside webapp

    Tokenized link is retreived from middleware and is iframed on page
*/

export default function FormsPage(props) {
  return (
    <div className="iframe-holder">
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={formError} alt="email-error" width={"80%"} />
        </div>
    </div>
  );
}
