import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Container } from 'react-bootstrap';
import { DesignService } from "../services";
import { useSelector } from "react-redux";
import DesignContainer from "./generics/DesignContainer";
import CircleLottie from "./generics/CircleLottie";
import DesignHubThumb from "./../assets/images/Thumbnail-LDA-Design-Hub.png";

const DesignModal = ({ showModal, handleClose }) => {
    const [designData, setDesignData] = useState([]);
    const [isDataLoad, setIsDataLoad] = useState(false);
    const uuid = useSelector(state => state.isUUID);
    useEffect(() => {
        async function fetchDesign() {
            await DesignService.getDesigns({ uuid: uuid.uuid })
                .then(res => {
                    setDesignData(res.data);
                })
                .catch(err => {
                    console.log("Error retrieving designs. Error: ", err);
                });
            setIsDataLoad(true)
        }
        fetchDesign();
    }, []);
    return (
        <Modal
            show={showModal}
            onHide={handleClose}
            size="xl"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdropClassName="designModal"
            scrollable={true}
        >
            <Modal.Header style={{ backgroundColor: '#223150' }}>
                <Modal.Title><h4 className="h4-a" style={{ color: "white" }}>My Designs</h4></Modal.Title>
            </Modal.Header>
            {isDataLoad ?
                designData.length > 0 ?
                    <Modal.Body>
                        <Container className="designModalContainer">
                            <Row className="row-d">
                                {designData.map((designObj, index) => (
                                    /*<DesignContainer objKey={ designObj.imageKey } url={ designObj.imageUrl } name={ designObj.imageName } />*/
                                    <Col className="col-d" key={index} sm={3}>
                                        <DesignContainer objKey={designObj.imageKey} url={designObj.imageUrl} name={designObj.imageName} />
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </Modal.Body>
                : <div className="noDesigns">
                    <h4 className="h4-a">
                        You don’t have any designs. Head over to <a href="https://www.canva.com/" target="_blank" rel="noopener noreferrer" className="canvaLink">Canva</a> and use the LDA Design Hub application when you publish your next design to view it here.
                    </h4>
                    <img src={DesignHubThumb} alt="designHubThumb" className="designHubThumb"></img>
                </div>
            : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 450 }}>
                <CircleLottie />
            </div>
            }
            <Modal.Footer className="justify-content-center">
                <Button
                    className="btn-bb"
                    onClick={handleClose}
                    data-toggle="button"
                    aria-pressed="true"
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DesignModal;