import React from "react";
import { useSelector } from 'react-redux';


import UserDash from "./dashboards/userDash";
import AdminDash from "./dashboards/adminDash";

// Deprecated Lazy Load
//const LazyUser = lazy(() => import('./dashboards/userDash'));
//const LazyAdmin = lazy(() => import('./dashboards/adminDash'));


// Intermediate switcher component to render either user or admin dashboard

export default function Home() {

    const userType = useSelector(state => state.isUser);
    const uuid = useSelector(state => state.isUUID);

    if (userType.userType === "user") {
        return (

            <UserDash uuid={uuid.uuid} />

        );
    }
    if (userType.userType === "admin") {
        return (

            <AdminDash uuid={uuid.uuid} />

        );
    }
}

